@charset "UTF-8";
/*-------------------------------------------------------*/
/* Table of Content

1. General
2. Typography
3. Navigation
4. Layout
5. Blog
6. Shortcodes
7. Shop Products
8. Cart
9. Checkout
10. Footer
11. Responsive
12. Spacings

/*-------------------------------------------------------*/
/*-------------------------------------------------------*/
/* General
/*-------------------------------------------------------*/
.clearfix {
  *zoom: 1; }
  .clearfix:before, .clearfix:after {
    display: table;
    line-height: 0;
    content: ""; }
  .clearfix:after {
    clear: both; }

.clear {
  clear: both; }

.oh {
  overflow: hidden; }

.ov {
  overflow: visible; }

.relative {
  position: relative; }

.section-wrap {
  padding: 80px 0;
  overflow: hidden;
  background-attachment: fixed;
  background-size: cover; }

.main-container {
  margin: auto; }

@media (min-width: 1200px) {
  .rev_slider_wrapper.container {
    width: 1170px; } }

.mobile section {
  background-attachment: scroll !important; }

.bg-dark {
  background-color: #171717; }

.bg-darkblue {
  background-color: #152332; }

.bg-light {
  background-color: #f7f8f9; }

.bg-white {
  background-color: #fff; }

.bg-color {
  background-color: #e40115; }

.white {
  color: #fff; }

.static {
  position: static; }

.block {
  display: block; }

.left {
  float: left; }

.right {
  float: right; }

.valign {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.container-fluid.semi-fluid {
  padding: 0 50px; }

.img-fw {
  width: 100%; }

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.02em; }

.last {
  margin-bottom: 0 !important; }

@media (max-width: 991px) {
  .md-text-center {
    text-align: center; } }

@media (max-width: 767px) {
  .sm-text-center {
    text-align: center; } }

@media (max-width: 480px) {
  .xs-text-center {
    text-align: center; } }

img.alignleft {
  float: left;
  margin: 10px 20px 10px 0; }

img.alignright {
  float: right;
  margin: 10px 0 10px 20px; }

.animation-wrap {
  overflow: hidden; }

::-moz-selection {
  color: #fff;
  background-color: #e40115; }

::-webkit-selection {
  color: #fff;
  background-color: #e40115; }

::selection {
  color: #fff;
  background-color: #e40115; }

:focus {
  outline: none; }

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  background: #fff;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  color: #7f7f7f; }
  body img {
    border: none;
    max-width: 100%;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none; }

video {
  height: 100%;
  width: 100%; }

/* Preloader
-------------------------------------------------------*/
.loader-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999; }

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  font-size: 0;
  color: #e40115;
  display: inline-block;
  margin: -25px 0 0 -25px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }
  .loader div {
    background-color: #e40115;
    display: inline-block;
    float: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    opacity: .5;
    border-radius: 50%;
    -webkit-animation: ballPulseDouble 2s ease-in-out infinite;
    animation: ballPulseDouble 2s ease-in-out infinite; }
  .loader div:last-child {
    -webkit-animation-delay: -1s;
    animation-delay: -1s; }

@-webkit-keyframes ballPulseDouble {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes ballPulseDouble {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/* Scroll Down icon
-------------------------------------------------------*/
.scroll-down {
  font-size: 16px;
  width: 38px;
  height: 38px;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  line-height: 38px;
  z-index: 50;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -19px;
  border-radius: 50%; }
  .scroll-down.dark {
    background-color: transparent;
    border: 2px solid #000; }
    .scroll-down.dark i {
      color: #000;
      left: 11px; }
  .scroll-down.bottom-140 {
    bottom: 140px; }
    @media only screen and (max-width: 767px) {
      .scroll-down.bottom-140 {
        bottom: 80px; } }
  .scroll-down a {
    line-height: 38px;
    position: relative;
    z-index: 50; }
  .scroll-down i {
    color: #fff;
    -webkit-animation: scroll-down-icon 1s infinite;
    -moz-animation: scroll-down-icon 1s infinite;
    -o-animation: scroll-down-icon 1s infinite;
    animation: scroll-down-icon 1s infinite;
    position: absolute;
    left: 13px;
    font-size: 20px; }

@-webkit-keyframes scroll-down-icon {
  0% {
    bottom: 3px; }
  50% {
    bottom: 8px; }
  100% {
    bottom: 3px; } }

@-moz-keyframes scroll-down-icon {
  0% {
    bottom: 3px; }
  50% {
    bottom: 8px; }
  100% {
    bottom: 3px; } }

@-o-keyframes scroll-down-icon {
  0% {
    bottom: 3px; }
  50% {
    bottom: 8px; }
  100% {
    bottom: 3px; } }

@keyframes scroll-down-icon {
  0% {
    bottom: 3px; }
  50% {
    bottom: 8px; }
  100% {
    bottom: 3px; } }

.row.row-4 {
  margin: 0 -2px; }

.row.row-4 > div {
  padding: 0 2px; }

.row.row-10 {
  margin: 0 -5px; }

.row.row-10 > div {
  padding: 0 5px; }

.row.row-12 {
  margin: 0 -6px; }

.row.row-12 > div {
  padding: 0 6px; }

.row.row-16 {
  margin: 0 -8px; }

.row.row-16 > div {
  padding: 0 8px; }

.row.row-20 {
  margin: 0 -10px; }

.row.row-20 > div {
  padding: 0 10px; }

/* Row multiple items
-------------------------------------------------------*/
.items-grid .col-xs-6:nth-child(2n + 3) {
  clear: left; }

.items-grid .col-xs-4:nth-child(3n + 4) {
  clear: left; }

.items-grid .col-xs-3:nth-child(4n + 5) {
  clear: left; }

.items-grid .col-xs-2:nth-child(6n + 7) {
  clear: left; }

.items-grid .col-xs-1:nth-child(12n + 13) {
  clear: left; }

@media (min-width: 768px) {
  .items-grid .col-xs-6:nth-child(2n + 3) {
    clear: none; }
  .items-grid .col-xs-4:nth-child(3n + 4) {
    clear: none; }
  .items-grid .col-xs-3:nth-child(4n + 5) {
    clear: none; }
  .items-grid .col-xs-2:nth-child(6n + 7) {
    clear: none; }
  .items-grid .col-xs-1:nth-child(12n + 13) {
    clear: none; }
  .items-grid .col-sm-6:nth-child(2n + 3) {
    clear: left; }
  .items-grid .col-sm-4:nth-child(3n + 4) {
    clear: left; }
  .items-grid .col-sm-3:nth-child(4n + 5) {
    clear: left; }
  .items-grid .col-sm-2:nth-child(6n + 7) {
    clear: left; }
  .items-grid .col-sm-1:nth-child(12n + 13) {
    clear: left; } }

@media (min-width: 992px) {
  .items-grid .col-sm-6:nth-child(2n + 3) {
    clear: none; }
  .items-grid .col-sm-4:nth-child(3n + 4) {
    clear: none; }
  .items-grid .col-sm-3:nth-child(4n + 5) {
    clear: none; }
  .items-grid .col-sm-2:nth-child(6n + 7) {
    clear: none; }
  .items-grid .col-sm-1:nth-child(12n + 13) {
    clear: none; }
  .items-grid .col-md-6:nth-child(2n + 3) {
    clear: left; }
  .items-grid .col-md-4:nth-child(3n + 4) {
    clear: left; }
  .items-grid .col-md-3:nth-child(4n + 5) {
    clear: left; }
  .items-grid .col-md-2:nth-child(6n + 7) {
    clear: left; }
  .items-grid .col-md-1:nth-child(12n + 13) {
    clear: left; } }

@media (min-width: 1200px) {
  .items-grid .col-md-6:nth-child(2n + 3) {
    clear: none; }
  .items-grid .col-md-4:nth-child(3n + 4) {
    clear: none; }
  .items-grid .col-md-3:nth-child(4n + 5) {
    clear: none; }
  .items-grid .col-md-2:nth-child(6n + 7) {
    clear: none; }
  .items-grid .col-md-1:nth-child(12n + 13) {
    clear: none; }
  .items-grid .col-lg-6:nth-child(2n + 3) {
    clear: left; }
  .items-grid .col-lg-4:nth-child(3n + 4) {
    clear: left; }
  .items-grid .col-lg-3:nth-child(4n + 5) {
    clear: left; }
  .items-grid .col-lg-2:nth-child(6n + 7) {
    clear: left; }
  .items-grid .col-lg-1:nth-child(12n + 13) {
    clear: left; } }

/* 5 columns
-------------------------------------------------------*/
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.col-xs-5ths {
  width: 20%;
  float: left; }

@media (min-width: 767px) {
  .col-sm-5ths {
    width: 20%;
    float: left; } }

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left; } }

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left; } }

/* Hover Overlays
-------------------------------------------------------*/
.hover-overlay {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  backface-visibility: hidden;
  -webkit-transform: translateZ(0, 0, 0);
  -moz-transform: translateZ(0, 0, 0);
  -ms-transform: translateZ(0, 0, 0);
  -o-transform: translateZ(0, 0, 0);
  transform: translateZ(0, 0, 0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.hover-trigger:hover .hover-overlay {
  opacity: 1;
  visibility: visible; }

/* Hover Scale
-------------------------------------------------------*/
.hover-scale img {
  -webkit-transition: all 1.5s ease-out;
  -moz-transition: all 1.5s ease-out;
  -ms-transition: all 1.5s ease-out;
  -o-transition: all 1.5s ease-out;
  transition: all 1.5s ease-out; }

.hover-scale:hover img {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1); }

/* List Items
-------------------------------------------------------*/
.list-dividers > li {
  position: relative;
  padding: 9px 0;
  border-bottom: 1px solid #ebebeb; }
  .list-dividers > li:last-child {
    padding-bottom: 0;
    border-bottom: none; }

.list-no-dividers > li {
  position: relative;
  padding: 5px 0; }

.alert-special {
  background-color: #e40115;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
  text-align: center;
  border: none;
  margin-bottom: 0 !important; }
  .alert-special p {
    margin: 0;
    padding: 0;
    color: #fff;
    cursor: pointer; }
    .alert-special p strong span {
      font-size: 18px; }

#offerModal .modal-dialog {
  width: 400px; }
  #offerModal .modal-dialog .modal-content {
    background: url(../img/offer-coupon.jpg) no-repeat;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    border: none;
    width: 400px;
    height: 400px;
    padding-top: 25px; }
    #offerModal .modal-dialog .modal-content .closeOffer {
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      color: #333;
      border: none;
      font-size: 4rem;
      line-height: 4rem;
      padding: 6px 10px 18px 40px; }
    #offerModal .modal-dialog .modal-content .offer-coupon {
      width: 200px;
      height: 80px;
      line-height: 77px;
      text-align: center;
      margin: 0 auto;
      border: 3px #e40115 dashed;
      font-size: 4rem;
      font-weight: 600;
      color: #e40115; }
    #offerModal .modal-dialog .modal-content .offer-text {
      padding: 0 30px;
      position: absolute;
      bottom: 130px;
      text-align: center;
      font-size: 18px;
      color: #fff; }

/*-------------------------------------------------------*/
/* Typography
/*-------------------------------------------------------*/
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  margin-top: 0;
  color: #000;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.05em; }

h1 {
  font-size: 32px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 22px; }

h4 {
  font-size: 20px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  color: inherit; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  color: #000;
  font-size: 85%;
  font-weight: bold; }

.nocaps {
  text-transform: none;
  letter-spacing: normal; }

.heading {
  position: relative;
  margin-bottom: 0; }
  .heading.large {
    font-size: 42px; }

.heading-row {
  margin-bottom: 50px; }

.subheading {
  font-weight: 600;
  display: inline-block;
  color: #7f7f7f;
  font-size: 16px;
  margin-bottom: 9px; }

a {
  text-decoration: none;
  color: #e40115;
  outline: none;
  -webkit-transition: color 0.1s ease-in-out;
  -moz-transition: color 0.1s ease-in-out;
  -ms-transition: color 0.1s ease-in-out;
  -o-transition: color 0.1s ease-in-out;
  transition: color 0.1s ease-in-out; }
  a:hover {
    text-decoration: none;
    color: #171717;
    outline: none; }
  a:focus {
    color: #e40115;
    text-decoration: none;
    outline: none; }

b, strong {
  color: #000; }

p {
  font-size: 14px;
  color: #7f7f7f;
  font-weight: normal;
  line-height: 24px; }

.list-inline {
  margin: 0; }

.bottom-line:after {
  content: "";
  display: block;
  width: 46px;
  border-bottom: 2px solid #e40115;
  margin: 17px auto 0;
  z-index: 1;
  position: relative; }

.bottom-line.left-align:after {
  margin: 22px auto 22px 0; }

.bottom-line.grey:after {
  border-color: #ebebeb; }

.bottom-line.full-grey:after {
  width: 100%;
  border-color: #ebebeb;
  margin-top: 16px; }

@media (min-width: 768px) {
  .lead {
    font-size: 18px; } }

.lead {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px; }

blockquote {
  padding: 0 0 20px;
  margin: 0;
  border: none; }
  blockquote > p {
    font-size: 16px;
    line-height: 28px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 10px !important;
    color: #7f7f7f;
    position: relative; }
  blockquote > span {
    color: #e40115;
    font-size: 13px; }

.blockquote-style-1 {
  padding: 40px;
  background-color: #f7f8f9; }
  .blockquote-style-1 span {
    color: #e40115; }

.blockquote-style-2 {
  padding: 20px 40px;
  border-left: 2px solid #e40115; }

.dropcap {
  font-family: "Montserrat", sans-serif; }

.dropcap.style-1 {
  float: left;
  color: #e40115;
  font-size: 47px;
  line-height: 48px;
  padding-right: 10px; }

.dropcap.style-2 {
  float: left;
  color: #fff;
  text-align: center;
  background-color: #e40115;
  width: 38px;
  height: 38px;
  font-size: 24px;
  line-height: 40px;
  margin: 7px 10px 0 0; }

.highlight {
  padding: 3px 5px;
  color: #fff;
  background-color: #e40115; }

.list li {
  line-height: 24px;
  position: relative; }
  .list li i {
    line-height: 21px; }

.bullets li,
.arrows li,
.checks li,
.numbers li {
  margin-bottom: 20px;
  color: #7f7f7f; }

.bullets li {
  padding-left: 15px; }

.bullets li:before {
  content: "\2022";
  font-size: 18px;
  position: absolute;
  left: 0;
  color: #e40115; }

.arrows i {
  margin-right: 5px; }

.arrows i,
.checks i {
  color: #e40115; }

.checks i {
  font-size: 12px;
  margin-right: 7px;
  vertical-align: middle; }

ol.numbers {
  padding-left: 20px; }

.columns [class^="col"],
.section-headings [class^="col"] {
  margin-bottom: 40px; }

.widget-title {
  font-size: 16px;
  margin-bottom: 30px; }

/*-------------------------------------------------------*/
/* Navigation
/*-------------------------------------------------------*/
@media (max-width: 991px) {
  .navbar-header {
    float: none; }
  .navbar-left, .navbar-nav, .navbar-right {
    float: none !important; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px; }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-nav {
    margin-top: 7.5px; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  .collapse.in {
    display: block !important; }
  .navbar-nav .open .dropdown-menu, .dropdown-trigger.active + .dropdown-menu {
    display: block;
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent !important;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; } }

.navbar {
  margin-bottom: 0;
  border: none;
  min-height: 100px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 120; }

.navigation {
  width: 100%;
  line-height: 0; }
  .navigation.offset {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .navigation.scrolling {
    -webkit-transform: translate3d(0, 0px, 0);
    -moz-transform: translate3d(0, 0px, 0);
    -ms-transform: translate3d(0, 0px, 0);
    -o-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0); }
  .navigation.sticky {
    position: fixed;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 60px;
    top: 0;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 120; }
    .navigation.sticky.scrolling {
      opacity: 1;
      visibility: visible; }
    .navigation.sticky .navbar-nav > li > a {
      line-height: 60px;
      color: #000; }
    .navigation.sticky .nav-right,
    .navigation.sticky .nav-social-icons .social-icons a {
      color: #000; }
      .navigation.sticky .nav-right:hover,
      .navigation.sticky .nav-social-icons .social-icons a:hover {
        color: #000 !important; }
    .navigation.sticky #nav-icon span {
      background-color: #000; }

@media only screen and (max-width: 991px) {
  header .flex-parent:not(.top-bar-links) {
    display: block; } }

header .flex-child {
  flex: 1 0 0; }
  @media only screen and (max-width: 991px) {
    header .flex-child {
      width: 100%; } }

header .flex-child.flex-right {
  text-align: right; }

.nav-wrap.flex-child {
  flex: 2 0 0; }

.logo-container {
  padding-right: 15px;
  float: left; }

.logo-wrap {
  display: table;
  width: 100%; }
  .logo-wrap > a {
    display: table-cell;
    vertical-align: middle;
    height: 100px; }
  .logo-wrap.shrink > a {
    height: 60px; }

.logo,
.navigation-overlay .logo-wrap > a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.logo,
.logo-dark {
  max-height: 38px; }

.navbar-header,
.nav-wrap {
  padding: 0 15px; }

.navbar-nav {
  margin: 0;
  display: inline-block;
  float: none; }
  .navbar-nav > li > a {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.01em;
    color: #000;
    font-size: 13px;
    padding: 0 17px;
    line-height: 100px; }
    .navbar-nav > li > a:hover {
      color: #e40115; }
  .navbar-nav > .active > a {
    color: #e40115; }
  .navbar-nav > li:last-child > a {
    padding-right: 0; }

.navbar-collapse {
  border: none;
  padding-left: 0;
  padding-right: 0;
  max-height: 100% !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none; }
  .navbar-collapse.in {
    overflow-x: hidden; }

.navigation.scrolling .navbar-nav > li > a:hover,
.navigation.scrolling .navbar-nav > .active > a,
.navigation.scrolling .navbar-nav > .active > a:focus,
.navigation.scrolling .navbar-nav > .active > a:hover {
  color: #e40115; }

.navbar-nav > .open > a,
.navbar-nav > .open > a:focus,
.navbar-nav > .open > a:hover {
  background-color: transparent;
  color: #e40115; }

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover,
.nav > li > a:focus,
.nav > li > a:hover {
  background-color: transparent;
  border-color: #ebebeb;
  text-decoration: none; }

.navbar-nav .dropdown-menu {
  left: 0; }
  .navbar-nav .dropdown-menu.menu-right {
    left: auto;
    right: 0; }

.dropdown-menu {
  min-width: 230px;
  margin: 0;
  padding: 20px 0;
  background-color: #171717;
  border: none;
  border-radius: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); }
  .dropdown-menu li > a {
    padding: 9px 30px;
    color: #878787;
    font-size: 11px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 400; }
  .dropdown-menu.megamenu, .dropdown-menu.megamenu-wide {
    padding: 0 20px; }
  .dropdown-menu .megamenu-wrap ul > li > a {
    display: block;
    clear: both;
    line-height: 1.42857143;
    white-space: nowrap;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .dropdown-menu .megamenu-wrap ul > li > a i {
      margin-right: 6px;
      width: 16px;
      text-align: center; }

.menu-list li > a {
  border: none; }

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.megamenu .menu-list > li > a:hover,
.megamenu-wide .menu-list > li > a:hover {
  background-color: transparent; }

.megamenu-item {
  border-right: 1px solid #353535;
  padding: 40px 30px 30px; }
  .megamenu-item:last-child {
    border-right: none; }
  .megamenu-item.no-headings {
    padding: 30px; }

.megamenu-wrap .menu-list > li > a {
  padding: 9px 0; }

.megamenu-wrap .menu-list > li:last-child > a {
  border-bottom: none; }

.megamenu-item span {
  display: block;
  margin-bottom: 15px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.02em; }

.navbar .dropdown-menu {
  margin-top: 0; }

.dropdown-submenu {
  position: relative; }
  .dropdown-submenu .dropdown-menu {
    top: -18px;
    left: 100%; }
  .dropdown-submenu > a:after {
    font-family: "FontAwesome";
    position: absolute;
    content: "\f105";
    font-size: 16px;
    right: 20px;
    line-height: 1;
    color: #7f7f7f; }

.navbar-nav .dropdown > i,
.navbar-nav .dropdown-submenu > i {
  display: none; }

@media (min-width: 992px) {
  .dropdown-menu,
  .dropdown-submenu > .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out; }
  .dropdown:hover > .dropdown-menu,
  .dropdown-submenu:hover > .dropdown-menu {
    opacity: 1;
    visibility: visible; }
  .dropdown-menu > li > a:focus,
  .dropdown-menu > li > a:hover,
  .megamenu .menu-list > li > a:hover,
  .megamenu-wide .menu-list > li > a:hover {
    color: #fff; }
  .nav-type-2 .dropdown-menu {
    left: 70%;
    top: -10px; } }

/*-------------------------------------------------------*/
/* Navigation Right Links
/*-------------------------------------------------------*/
.navigation.sticky #nav-icon .nav-icon-inner,
.navigation.sticky .nav-right li,
.navigation-overlay.sticky #nav-icon .nav-icon-inner,
.navigation.sticky .nav-social-icons .social-icons {
  height: 60px; }

.nav-right {
  float: right;
  color: #000;
  padding-left: 15px; }
  .nav-right > ul {
    display: inline-block; }
  .nav-right li {
    display: table-cell;
    vertical-align: middle;
    font-size: 20px;
    padding-right: 24px;
    height: 100px; }
  .nav-right li:last-child {
    padding-right: 0; }
  .nav-right.menu-socials a {
    font-size: 15px;
    color: #000; }
    .nav-right.menu-socials a:hover {
      color: #e40115; }

.nav-wrap ~ .nav-right {
  padding: 0 15px; }

/* Menu Search
-------------------------------------------------------*/
.nav-search {
  position: relative;
  display: block;
  color: inherit;
  font-size: 20px; }
  .nav-search:hover {
    color: inherit; }

.search-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 121;
  background-color: #fff; }
  .search-wrap .search-inner {
    position: relative;
    width: 100%;
    height: 100%; }
  .search-wrap .search-cell {
    position: absolute;
    top: 20%;
    width: 100%; }
  .search-wrap .search-field-holder {
    width: 50%;
    margin: auto;
    position: relative;
    animation: slideInUp .3s; }
  .search-wrap .form-control {
    height: 66px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #ebebeb;
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    margin-bottom: 0;
    color: #000;
    position: relative; }
  .search-wrap input.form-control,
  .search-wrap input.form-control:focus {
    background-color: #fff; }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

input.main-search-input::-webkit-input-placeholder {
  color: #000; }

input.main-search-input:-moz-placeholder {
  color: #000;
  opacity: 1; }

input.main-search-input::-moz-placeholder {
  color: #000;
  opacity: 1; }

input.main-search-input:-ms-input-placeholder {
  color: #000; }

.search-close {
  position: absolute;
  top: 11px;
  right: 5px;
  font-size: 30px;
  color: #000;
  cursor: pointer; }

.mobile-links li > a {
  padding: 15px 0 15px 15px;
  line-height: 20px;
  border-bottom: 1px solid #ebebeb;
  color: #7f7f7f;
  display: block; }
  .mobile-links li > a:hover {
    color: #e40115; }

/* Menu Cart
-------------------------------------------------------*/
.nav-cart-outer {
  display: table;
  position: relative;
  height: 100%; }

.nav-cart-inner {
  display: table-cell;
  vertical-align: middle; }

.nav-cart-icon {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  line-height: 19px;
  color: #fff;
  background-color: #000;
  border-radius: 2px; }
  .nav-cart-icon:before {
    content: '';
    display: block;
    width: 10px;
    height: 5px;
    top: -5px;
    left: 5px;
    position: absolute;
    border: 2px solid #000;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom: 0; }
  .nav-cart-icon:hover {
    color: #fff; }

/* Menu Cart Dropdown
-------------------------------------------------------*/
.nav-cart-container {
  opacity: 0;
  visibility: hidden;
  min-width: 250px;
  max-width: 300px;
  position: absolute;
  left: auto;
  right: 0;
  background-color: #fff;
  padding: 30px;
  line-height: 1;
  border: 1px solid #ebebeb;
  text-align: left;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 201; }
  .nav-cart-container:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    top: -30px;
    height: 30px; }

.nav-cart-items {
  position: relative; }

.nav-cart-item {
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f2f2f2;
  position: relative; }
  .nav-cart-item:first-child {
    margin-top: 0; }
  .nav-cart-item .nav-cart-img {
    float: left;
    width: 60px;
    margin-right: 20px; }
  .nav-cart-item .nav-cart-price {
    color: #7f7f7f;
    font-size: 13px; }

.nav-cart-title {
  position: relative;
  overflow: hidden;
  margin-right: 30px; }
  .nav-cart-title > a {
    position: relative;
    color: #000;
    font-size: 14px;
    vertical-align: top;
    line-height: 1.5; }
    .nav-cart-title > a:hover {
      color: #e40115; }

.nav-cart-price {
  margin-top: 5px; }

.nav-cart-remove {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0; }

.nav-cart-summary {
  margin-top: 10px;
  font-weight: 700;
  color: #000;
  font-size: 14px; }
  .nav-cart-summary .total-price {
    float: right; }

.nav-cart-actions a {
  width: 100%; }

.nav-cart:hover .nav-cart-container {
  opacity: 1;
  visibility: visible; }

.mobile-cart {
  float: right;
  height: 100%;
  margin-right: 12px;
  color: #000; }

/* Navigation Register
-------------------------------------------------------*/
.nav-register a {
  display: inline-block;
  line-height: 1;
  font-size: 14px;
  color: #000; }
  .nav-register a:hover {
    color: #e40115; }

.nav-register span {
  display: inline-block;
  margin: 0 3px 0 7px;
  line-height: 1; }

/* Navigation Toggle
-------------------------------------------------------*/
.navbar-toggle {
  margin-top: 13px;
  border: none;
  z-index: 50;
  margin-right: 5px; }
  .navbar-toggle .icon-bar {
    background-color: #000;
    width: 18px; }
  .navbar-toggle:focus .icon-bar, .navbar-toggle:hover .icon-bar {
    background-color: #e40115; }
  .navbar-toggle:focus, .navbar-toggle:hover {
    background-color: transparent; }

/* Go to Top
-------------------------------------------------------*/
#back-to-top {
  display: block;
  z-index: 100;
  width: 34px;
  height: 34px;
  text-align: center;
  font-size: 16px;
  position: fixed;
  bottom: -34px;
  right: 20px;
  line-height: 32px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none; }
  #back-to-top i {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  #back-to-top a {
    display: block;
    color: #fff; }
  #back-to-top.show {
    bottom: 20px; }
  #back-to-top:hover {
    background-color: #e40115;
    bottom: 24px; }
  #back-to-top:hover i {
    color: #fff; }

/*-------------------------------------------------------*/
/* Nav Type-2 (Left Nav)
/*-------------------------------------------------------*/
.content-wrap {
  margin-left: 320px;
  position: relative;
  width: auto; }

.vertical-nav .container {
  max-width: 100%; }

.vertical-nav .main-wrapper {
  margin-left: 320px;
  position: relative;
  width: auto; }

.vertical-nav .header-wrap {
  width: 320px;
  height: 100%;
  background-color: #fff;
  padding: 70px 50px; }

.nav-type-2 {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  height: 100%; }
  .nav-type-2 .navbar,
  .nav-type-2 .row {
    height: 100%; }
  .nav-type-2 .navbar-header,
  .nav-type-2 .logo-container {
    width: 100%; }
  .nav-type-2 .logo-container {
    padding: 0 15px; }
  .nav-type-2 .navbar-header {
    padding: 0; }
  .nav-type-2 .logo-wrap > a {
    height: auto; }
  .nav-type-2 .nav {
    width: 100%;
    margin-top: 55px; }
  .nav-type-2 .navbar-nav > li {
    float: none; }
  .nav-type-2 .navbar-nav > li > a {
    padding: 17px 0;
    line-height: 1; }
  .nav-type-2 .nav-cart {
    margin-top: 40px; }
  .nav-type-2 .nav-cart-outer,
  .nav-type-2 .nav-cart-amount {
    float: left; }
  .nav-type-2 .nav-cart-amount a {
    color: #000;
    display: inline-block;
    margin-top: 2px;
    margin-left: 10px; }
  .nav-type-2 .searchbox {
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0; }
  .nav-type-2 .search-button {
    right: 0; }
  .nav-type-2 .social-icons {
    position: absolute;
    bottom: 100px; }
  .nav-type-2 .copyright {
    position: absolute;
    bottom: 30px; }
    .nav-type-2 .copyright span {
      line-height: 24px; }

@media (max-height: 720px) {
  .nav-type-2 .social-icons,
  .nav-type-2 .copyright {
    position: static; } }

.nav-item-toggle > a:after {
  font-family: "FontAwesome";
  position: absolute;
  content: "\f107";
  right: 0;
  color: #7f7f7f; }

.nav-item-submenu li a {
  display: block;
  padding: 7px 0;
  line-height: 1;
  color: #7f7f7f; }
  .nav-item-submenu li a:hover {
    color: #e40115; }

/*-------------------------------------------------------*/
/* Top Bar
/*-------------------------------------------------------*/
.top-bar {
  background-color: #121212;
  width: 100%;
  position: relative;
  height: 44px;
  color: #a1a1a1; }

.top-bar-links {
  line-height: 44px;
  font-size: 12px; }

.top-bar > ul,
.top-bar-links > ul > li {
  display: inline-block;
  position: relative; }

.top-bar-links > ul > .top-bar-email {
  padding-right: 20px; }

.top-bar-links > ul:first-child > li i {
  margin-right: 5px;
  color: #5c5c5c; }

.top-bar-links .top-bar-email a {
  color: #a1a1a1; }

.top-bar-currency-language > li > a,
.top-bar-links > ul > li > a {
  color: #fff; }

.top-bar-currency-language i {
  margin-left: 5px; }

.currency-dropdown,
.language-dropdown {
  background-color: #fff;
  padding: 0 10px;
  position: absolute;
  border: 1px solid #f2f2f2;
  z-index: 1201;
  right: 15px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.language-dropdown {
  right: 0; }

.currency-dropdown ul li,
.language-dropdown ul li {
  line-height: 26px;
  border-top: 1px solid #f2f2f2;
  text-transform: uppercase;
  letter-spacing: 0.05em; }

.currency-dropdown ul li > a,
.language-dropdown ul li > a {
  color: #7f7f7f; }

.top-bar-currency-language > li:hover .currency-dropdown,
.top-bar-currency-language > li.language:hover .language-dropdown {
  visibility: visible;
  opacity: 1; }

.top-bar-links .top-bar-link a:after {
  content: "";
  border-right: 1px solid #606060;
  margin: 0 7px 0 10px; }

.top-bar-links .top-bar-link:last-child a:after {
  content: "";
  border-right: none;
  margin: 0; }

.top-bar-links li a:hover {
  color: #e40115; }

/*-------------------------------------------------------*/
/* Navigation Mobile Styles
/*-------------------------------------------------------*/
@media (max-width: 991px) {
  .navbar {
    min-height: 60px; }
  .navbar-header {
    width: 100%;
    padding-right: 0; }
  .logo-wrap > a,
  .nav-type-2 .logo-wrap > a,
  .navbar-header {
    height: 60px; }
  .navbar-collapse {
    text-align: left; }
  .logo-container {
    position: absolute;
    top: 0; }
  .logo-wrap {
    width: auto; }
  .nav > li > a {
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid #ebebeb;
    line-height: 1.2; }
  .nav > li:last-child > a {
    border-bottom: 0; }
  .navbar-nav {
    display: block; }
  .dropdown-menu > li > a,
  .megamenu-item span {
    padding: 15px 0 15px 20px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 0;
    line-height: 15px; }
  .dropdown-submenu .dropdown-menu > li > a {
    padding: 15px 0 15px 30px; }
  .dropdown-submenu .dropdown-menu > li > ul > li > a {
    padding: 15px 0 15px 45px; }
  .dropdown-submenu > a:after {
    display: none; }
  .nav-wrap {
    padding-left: 0;
    padding-right: 0;
    width: 100% !important; }
  .dropdown-menu {
    width: auto !important; }
  .dropdown-menu,
  .megamenu-item.no-headings {
    padding: 0; }
  .dropdown-menu.megamenu,
  .dropdown-menu.megamenu-wide {
    padding: 0 15px; }
  .megamenu-item {
    border-right: none;
    padding: 0; }
    .megamenu-item span {
      color: #7f7f7f; }
  .dropdown-menu .megamenu-wrap ul > li > a {
    display: block;
    padding: 15px 0 15px 20px;
    border-bottom: 1px solid #ebebeb;
    clear: both;
    font-weight: 400;
    line-height: 15px;
    color: #7f7f7f;
    white-space: nowrap; }
  .megamenu-wrap .menu-list > li:last-child > a {
    border-bottom: 1px solid #ebebeb; }
  .megamenu-wrap.container {
    width: 100%;
    padding: 0; }
  .mobile-search .form-control {
    height: 46px;
    border-bottom: 1px solid #ebebeb;
    background-color: transparent;
    border-left: none;
    border-top: none;
    border-right: none;
    margin-bottom: 0; }
  #mobile-search .search-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
    height: 46px;
    border: 0;
    cursor: pointer;
    background-color: transparent; }
  .nav-right {
    position: absolute;
    top: 0;
    right: 40px;
    padding-right: 0;
    color: #000 !important; }
    .nav-right li {
      height: auto; }
  .content-wrap {
    margin-left: 0; }
  .nav-type-2 {
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: auto;
    position: relative; }
    .nav-type-2 .header-wrap {
      width: 100%;
      padding: 0 15px; }
    .nav-type-2 .navbar-nav {
      position: relative;
      margin: 0; }
      .nav-type-2 .navbar-nav > li {
        display: block; }
      .nav-type-2 .navbar-nav > li > a {
        padding: 15px; }
    .nav-type-2 .nav-item-submenu li a {
      padding: 15px;
      border-bottom: 1px solid #ebebeb; }
    .nav-type-2 .nav-item-toggle > a:after {
      right: 15px; }
    .nav-type-2 .logo-container {
      width: auto; }
    .nav-type-2 .nav-cart {
      margin-top: 0; }
  .vertical-nav .main-wrapper {
    margin-left: 0; }
  .sticky-on-mobile .navbar {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }
  .navbar-nav .dropdown > i,
  .navbar-nav .dropdown-submenu > i {
    display: block;
    width: 46px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 50;
    cursor: pointer; } }

logo_dark
.hero-wrap {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

.hero-slide {
  background-size: cover;
  background-position: center center; }

.hero-holder {
  display: table;
  position: relative;
  width: 100%;
  height: 100%; }

.overlay:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.32);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.hero-slide.overlay:before {
  background-color: rgba(0, 0, 0, 0.36); }

.hero-message {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  text-align: center; }
  .hero-message h1 {
    color: #fff;
    font-size: 80px;
    font-weight: 700;
    line-height: 1; }
    @media only screen and (max-width: 991px) {
      .hero-message h1 {
        font-size: 60px; } }
    @media only screen and (max-width: 640px) {
      .hero-message h1 {
        font-size: 40px; } }

.hero-subtitle {
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  font-weight: 600;
  color: #fff;
  line-height: 1.6;
  letter-spacing: normal;
  font-size: 24px;
  max-width: 800px;
  margin: 8px auto 28px; }
  .hero-subtitle.lines {
    position: relative;
    padding: 0 20px;
    display: inline-block; }
    .hero-subtitle.lines:before {
      content: '';
      width: 38px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 100%; }
    .hero-subtitle.lines:after {
      content: '';
      width: 38px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 100%; }

.buttons-holder > a {
  margin: 5px; }

#owl-hero div[class^='hero-slide-'] {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

#owl-hero .container {
  height: 740px; }
  @media only screen and (max-width: 991px) {
    #owl-hero .container {
      height: 500px; } }

#owl-hero .owl-pagination {
  position: absolute;
  bottom: 30px; }

.slider-animated .hero-message {
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  transition-delay: .5s;
  opacity: 0; }

.slider-animated .active .hero-message {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1; }

/*-------------------------------------------------------*/
/* Fullscreen Hero
/*-------------------------------------------------------*/
.full-screen-hero {
  background-image: url(../img/hero/hero_bg.jpg);
  background-size: cover;
  background-position: center; }

.hero-message.style-2 .hero-text-holder {
  max-width: 500px; }

.hero-message.style-2 .hero-subtitle {
  font-size: 47px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0; }
  @media only screen and (max-width: 991px) {
    .hero-message.style-2 .hero-subtitle {
      font-size: 30px;
      line-height: 1.4; } }

.hero-message.style-2 p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 13px;
  margin-bottom: 28px; }

.hero-message.style-2.dark h1 {
  color: #000; }

.hero-message.style-2.dark p {
  color: #4f4f4f; }

.hero-message.style-2.dark h2 {
  color: #e40115; }

/*-------------------------------------------------------*/
/* Testimonials
/*-------------------------------------------------------*/
.testimonials .testimonial-text {
  font-size: 22px;
  color: #000;
  line-height: 34px;
  margin-top: 20px;
  margin-bottom: 14px;
  font-weight: 400; }

.testimonials .testimonial span {
  font-size: 13px;
  color: #7f7f7f; }

.testimonials .testimonial a {
  color: #e40115; }

.testimonials.bg-parallax {
  padding: 135px 0 115px;
  background-attachment: scroll; }
  .testimonials.bg-parallax.overlay:before {
    background-color: rgba(0, 0, 0, 0.7); }
  .testimonials.bg-parallax .testimonial-text,
  .testimonials.bg-parallax .testimonial span,
  .testimonials.bg-parallax .testimonial a {
    color: #fff; }
  .testimonials.bg-parallax .owl-pagination {
    margin-top: 40px; }

#owl-testimonials .container {
  width: auto;
  max-width: 1170px; }

/*-------------------------------------------------------*/
/* Partners
/*-------------------------------------------------------*/
.partners {
  padding: 85px 0; }

/*-------------------------------------------------------*/
/* Call To Action
/*-------------------------------------------------------*/
.call-to-action {
  position: relative;
  padding: 50px 0; }
  .call-to-action h3 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 0; }

.cta-buttons a {
  margin: 5px; }

.call-to-action.bg-color .btn-white:hover {
  background-color: rgba(255, 255, 255, 0.95); }

.call-to-action.bg-color .btn-white:hover span {
  color: #171717; }

/*-------------------------------------------------------*/
/* Team
/*-------------------------------------------------------*/
.team-member {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .team-member .team-img span {
    font-size: 13px; }

.team-title {
  font-size: 16px;
  margin-bottom: 3px; }

.team-img {
  position: relative;
  overflow: hidden;
  margin-bottom: 35px; }
  .team-img img {
    width: 100%; }
  .team-img .hover-overlay .social-icons {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .team-img .hover-overlay .social-icons a {
    margin-bottom: 0;
    color: #fff; }

.team-details span {
  font-size: 13px;
  margin-bottom: 20px;
  display: block; }

/*-------------------------------------------------------*/
/* From Blog
/*-------------------------------------------------------*/
.from-blog .entry-wrap {
  background-color: #fff;
  padding: 40px 10px 0;
  text-align: center; }
  .from-blog .entry-wrap p {
    margin-bottom: 0; }

.from-blog img {
  max-width: 100.1%; }

.from-blog .entry-title {
  font-size: 16px; }

.from-blog .entry-item {
  position: relative;
  margin-bottom: 40px; }

/*-------------------------------------------------------*/
/* Twitter List
/*-------------------------------------------------------*/
#tweets li {
  padding: 8px 0; }
  #tweets li:first-child {
    padding-top: 0; }
  #tweets li:last-child {
    padding-bottom: 0; }

#tweets .tweet {
  font-size: 15px; }
  #tweets .tweet a {
    word-wrap: break-word;
    color: #000; }
    #tweets .tweet a:hover {
      color: #e40115; }

#tweets .timePosted {
  font-size: 12px;
  margin-bottom: 0;
  color: #929292; }

/*-------------------------------------------------------*/
/* Contact
/*-------------------------------------------------------*/
.contact-item {
  margin-bottom: 35px; }
  .contact-item:last-child {
    margin-bottom: 0; }
  .contact-item ul:not(.list-dividers) li {
    padding: 5px 0; }
  .contact-item i {
    margin-right: 14px; }
  .contact-item h6 {
    font-size: 14px; }
  .contact-item a {
    color: #7f7f7f; }
    .contact-item a:hover {
      color: #e40115; }

address {
  line-height: 28px;
  margin-bottom: 0; }

#contact-form .message {
  height: 50px;
  width: 100%;
  font-size: 13px;
  line-height: 50px;
  text-align: center;
  float: none;
  margin-top: 20px;
  display: none;
  color: #fff; }

#contact-form .message.error {
  background-color: #ef5659; }

#contact-form .message.success {
  background-color: #2cb8a5; }

/*-------------------------------------------------------*/
/* Google Map
/*-------------------------------------------------------*/
.gmap {
  width: 100%;
  height: 450px; }

.gmap-btn {
  background-color: #f7f8f9;
  color: #000;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 70px;
  line-height: 70px;
  z-index: 2;
  position: relative;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .gmap-btn:hover, .gmap-btn.opened {
    color: #fff;
    background-color: #171717; }

.gmap.gmap-hidden {
  display: none; }

/*-------------------------------------------------------*/
/* Newsletter
/*-------------------------------------------------------*/
.newsletter {
  padding: 53px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  background-color: #f7f8f9; }
  .newsletter h4 {
    display: inline-block;
    margin-right: 38px;
    margin-bottom: 0;
    vertical-align: middle;
    font-size: 16px; }
    @media only screen and (max-width: 767px) {
      .newsletter h4 {
        display: block;
        margin-right: 0;
        margin-bottom: 10px; } }
  .newsletter .newsletter-form,
  .newsletter .newsletter-submit {
    display: inline-block; }
  .newsletter .newsletter-input {
    vertical-align: middle;
    background: #fff;
    margin-right: 6px;
    width: 280px;
    margin-bottom: 0 !important; }
    @media only screen and (max-width: 767px) {
      .newsletter .newsletter-input {
        width: 100%;
        margin-bottom: 10px !important; } }

/*-------------------------------------------------------*/
/* Call To Action
/*-------------------------------------------------------*/
.call-to-action {
  position: relative;
  padding: 62px 0;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb; }
  .call-to-action h3 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 0; }

.cta-buttons a {
  margin: 5px; }

/*-------------------------------------------------------*/
/* Page Title
/*-------------------------------------------------------*/
.page-title {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block;
  padding: 54px 0; }

.title-holder {
  position: relative; }

.title-text h1 {
  margin: 0;
  font-size: 28px; }

.title-text .entry-meta {
  margin-top: 10px;
  margin-bottom: 0; }

.breadcrumb {
  width: auto;
  background-color: transparent;
  padding: 0;
  padding-top: 5px;
  margin-bottom: 0;
  font-size: 14px; }
  .breadcrumb li,
  .breadcrumb li a {
    color: #a3a3a3; }
  .breadcrumb > .active {
    color: #000; }

/* With Bg Image
-------------------------------------------------------*/
.page-title.bg-img {
  background-size: cover;
  background-position: center; }
  .page-title.bg-img:before {
    background-color: rgba(0, 0, 0, 0.5); }
  .page-title.bg-img h1,
  .page-title.bg-img .breadcrumb li,
  .page-title.bg-img .breadcrumb li a {
    color: #fff; }

/*-------------------------------------------------------*/
/* About Us Page
/*-------------------------------------------------------*/
.result {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 8px;
  color: #000;
  display: block; }
  .result:last-of-type {
    margin-top: 20px; }

.intro-heading {
  margin-bottom: 15px; }

/*-------------------------------------------------------*/
/* 404
/*-------------------------------------------------------*/
.page-404 h2 {
  font-family: "Open Sans", sans-serif;
  color: #7f7f7f;
  font-weight: 400;
  font-size: 24px; }

.page-404 h1 {
  font-size: 160px;
  line-height: 1.2; }

.page-404 a,
.page-404 p {
  font-size: 14px; }

.page-404 form {
  max-width: 370px;
  margin: auto; }

/*-------------------------------------------------------*/
/* Flexbox
/*-------------------------------------------------------*/
.flex-parent {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center; }

.flex-child {
  flex: 1; }

/*-------------------------------------------------------*/
/* Blog Standard
/*-------------------------------------------------------*/
.entry-img {
  position: relative;
  overflow: hidden; }

.entry-meta {
  margin-bottom: 14px; }

.entry-meta li {
  color: #a3a3a3;
  font-size: 12px;
  display: inline-block;
  margin-right: 15px; }
  .entry-meta li:last-child {
    margin-right: 0; }
  .entry-meta li a {
    color: inherit; }
    .entry-meta li a:hover {
      color: #e40115; }
  .entry-meta li i {
    margin-right: 5px; }

.entry-wrap {
  position: relative;
  padding-top: 38px; }

.entry-title {
  font-size: 20px;
  margin-bottom: 7px;
  line-height: 1.4; }
  .entry-title a:hover {
    color: #e40115; }

.post-content .entry-img,
.post-content .entry-slider,
.post-content .entry-video {
  position: relative;
  overflow: hidden; }

.blog-standard .entry-item {
  margin-bottom: 50px;
  position: relative; }

.blog-standard .post-content p {
  margin-bottom: 23px; }

.read-more {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700; }

.post-content {
  padding-right: 3.2%; }

/* Quote Post
-------------------------------------------------------*/
.entry .blockquote-style-1 p > a {
  color: inherit; }
  .entry .blockquote-style-1 p > a:hover {
    color: #e40115; }

.entry .blockquote-style-1 span {
  font-size: 14px;
  display: block; }

/* Video Post
-------------------------------------------------------*/
.entry-video iframe {
  width: 100%;
  display: block;
  border: 0; }

/*-------------------------------------------------------*/
/* Pagination
/*-------------------------------------------------------*/
.pagination-wrap {
  margin-top: 30px; }

.pagination {
  margin: 0 auto;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center; }

.pagination a,
.pagination span {
  font-size: 14px;
  width: 40px;
  height: 40px;
  line-height: 38px;
  margin: 0 3px;
  text-align: center;
  border: 2px solid #ebebeb;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.pagination a {
  color: #000;
  vertical-align: middle; }
  .pagination a:hover {
    background-color: #e40115;
    border-color: transparent;
    color: #fff; }
  .pagination a > i {
    font-size: 15px; }

.pagination .current {
  color: #000;
  border-color: #000; }

/*-------------------------------------------------------*/
/* Sidebar
/*-------------------------------------------------------*/
.left-sidebar {
  float: left; }

.sidebar .widget-title {
  margin-bottom: 30px;
  font-size: 16px; }

.sidebar .widget {
  margin-bottom: 50px; }
  .sidebar .widget:last-child {
    margin-bottom: 30px; }
  .sidebar .widget > ul > li:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .sidebar .widget > ul > li:first-child {
    padding-top: 0; }

.widget.categories ul li.active-cat a {
  color: #000; }

.widget.categories ul li a {
  color: #7f7f7f; }
  .widget.categories ul li a:hover {
    color: #e40115; }

.widget.categories li span {
  right: 0;
  position: absolute;
  color: #7f7f7f; }

/* Entry List
-------------------------------------------------------*/
.post-small img {
  width: auto; }

.entry-list .entry-img {
  float: left;
  margin-right: 20px;
  margin-bottom: 0; }

.entry-list li .entry-meta {
  margin-top: 0;
  margin-bottom: 0; }

.entry-list ul > li {
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb; }
  .entry-list ul > li:first-child {
    padding-top: 0 !important; }
  .entry-list ul > li:last-child {
    border-bottom: none !important;
    padding-bottom: 0 !important; }
  .entry-list ul > li .entry-title {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 24px;
    font-family: "Open Sans", sans-serif;
    text-transform: none;
    letter-spacing: normal;
    color: #7f7f7f; }
  .entry-list ul > li .entry-meta li:first-child {
    padding-left: 0; }

.entry-list.w-thumbs ul > li {
  padding: 15px 0; }

.sidebar .entry-meta li {
  font-size: 12px; }
  .sidebar .entry-meta li a {
    color: #7f7f7f; }

/* Sidebar Search
-------------------------------------------------------*/
.search-button {
  position: absolute;
  top: 0;
  height: 100%;
  right: 10px;
  background-color: transparent;
  border: none; }

.search-button i {
  font-size: 13px; }
  .search-button i:hover {
    color: #171717; }

/* Tags
-------------------------------------------------------*/
.tags a {
  padding: 10px 13px;
  line-height: 1;
  margin: 0 6px 6px 0;
  font-size: 11px;
  color: #7f7f7f;
  background-color: #f7f8f9;
  display: inline-block;
  float: left;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .tags a:hover {
    background-color: #e40115;
    color: #fff;
    border-color: transparent; }

/*-------------------------------------------------------*/
/* Blog Single
/*-------------------------------------------------------*/
.post-single .article p {
  margin-bottom: 30px; }

/* Share / tags
/*-------------------------------------------------------*/
.entry-content .entry-tags span {
  display: inline-block;
  margin-right: 10px;
  color: #000; }

.entry-content .entry-tags a {
  color: #7f7f7f; }
  .entry-content .entry-tags a:hover {
    color: #e40115; }

@media only screen and (max-width: 991px) {
  .socials-share {
    text-align: left;
    margin-top: 15px; } }

/* Author
/*-------------------------------------------------------*/
.entry-author-box {
  padding-top: 30px;
  border-top: 1px solid #ebebeb;
  position: relative;
  margin-bottom: 48px; }

.entry-author-box .author-img {
  display: block;
  border-radius: 50%;
  float: left; }

.entry-author-box .author-name,
.entry-comments .comment-author {
  margin-bottom: 0;
  display: inline-block; }

.author-name {
  font-size: 14px;
  margin-right: 10px; }

.author-info {
  padding-left: 90px; }

.author-info span {
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 5px;
  display: inline-block; }

/* Comments
/*-------------------------------------------------------*/
.entry-comments .comment-avatar {
  position: absolute;
  display: block;
  border-radius: 50%; }

.entry-comments .comment-content {
  padding-left: 74px;
  margin-top: 5px; }

.entry-comments .comment-author {
  color: #000;
  margin-right: 10px; }

.entry-comments span {
  display: inline-block; }

.entry-comments span:last-of-type {
  margin-bottom: 10px; }

.entry-comments .comment-content span a {
  font-size: 12px;
  color: #929292; }

.entry-comments .comment-content p {
  margin-bottom: 10px; }

.comment-reply {
  padding-left: 10%; }

.comment-body {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ebebeb; }

#comment {
  margin-bottom: 20px; }

.comment-form input {
  margin-bottom: 20px !important; }

/*-------------------------------------------------------*/
/* Sliders
/*-------------------------------------------------------*/
.owl-carousel,
.flickity {
  overflow: hidden; }

.owl-carousel img {
  width: 100%; }

.owl-pagination {
  position: relative;
  margin-top: 30px;
  left: 0;
  display: block;
  text-align: center;
  width: 100%; }

.owl-page {
  display: inline-block;
  padding: 5px;
  position: relative; }
  .owl-page.active span {
    display: block;
    width: 12px;
    height: 12px;
    margin: 0;
    opacity: 1;
    border-color: transparent;
    background: #e40115; }
  .owl-page span {
    display: block;
    position: relative;
    width: 12px;
    height: 12px;
    opacity: 0.7;
    background: transparent;
    z-index: 100;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .owl-page span:hover {
      opacity: 1; }

.owl-carousel {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.owl-buttons {
  position: static; }

.owl-prev,
.flex-direction-nav .flex-prev,
.flickity-prev-next-button.previous {
  left: -40px; }

.owl-next,
.flex-direction-nav .flex-next,
.flickity-prev-next-button.next {
  right: -40px; }

.flex-direction-nav .flex-prev,
.flex-direction-nav .flex-next {
  opacity: 0; }

.owl-prev,
.owl-next,
.flex-direction-nav a,
.flickity-prev-next-button {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  text-align: center;
  line-height: 40px;
  z-index: 10;
  width: 40px;
  height: 40px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.flickity-prev-next-button,
.flex-direction-nav a {
  width: 60px;
  height: 80px;
  margin-top: -40px; }

.flickity-prev-next-button.previous {
  border-radius: 0 3px 3px 0; }

.flickity-prev-next-button.next {
  border-radius: 3px 0 0 3px; }

.flex-direction-nav a {
  background-color: #fff; }
  .flex-direction-nav a i {
    font-size: 26px;
    color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.flickity-prev-next-button svg {
  width: auto;
  height: auto; }

.owl-next:before,
#flexslider .flex-direction-nav .flex-next:before,
.flickity-prev-next-button:before {
  left: auto;
  right: 0; }

.owl-prev i,
.owl-next i,
#thumbs a i {
  position: relative;
  font-size: 28px;
  color: #000; }

.light-arrows .owl-prev i,
.light-arrows .owl-next i,
#flexslider.light-arrows a i {
  color: #fff; }

.light-arrows .flickity-prev-next-button .arrow {
  fill: #fff; }

.owl-carousel:hover .owl-next,
#flexslider:hover .flex-direction-nav .flex-next {
  opacity: 1;
  right: 10px; }

.owl-carousel:hover .owl-prev,
#flexslider:hover .flex-direction-nav .flex-prev {
  opacity: 1;
  left: 10px; }

.flickity-slider-wrap:hover .flickity-prev-next-button.previous {
  opacity: 1;
  left: 20px; }

.flickity-slider-wrap:hover .flickity-prev-next-button.next {
  opacity: 1;
  right: 20px; }

.flickity-prev-next-button,
.flickity-prev-next-button:hover {
  background-color: transparent; }

.flickity-prev-next-button:focus {
  box-shadow: none; }

.flickity-prev-next-button {
  border-radius: 0;
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none; }
  .flickity-prev-next-button .arrow {
    fill: #7f7f7f; }

.flickity-page-dots {
  position: relative;
  bottom: auto;
  margin-top: 50px; }

.flickity-page-dots .dot,
.flex-control-paging li a {
  height: 12px;
  width: 12px;
  opacity: 0.5;
  margin: 0 5px;
  border: 2px solid #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  background: transparent; }

.flickity-page-dots .dot:hover {
  opacity: 1; }

.flickity-page-dots .dot.is-selected {
  opacity: 1;
  background: #e40115;
  border-color: transparent; }

.flex-control-nav {
  bottom: 15px; }
  .flex-control-nav li {
    margin: 0; }

.is-dragging {
  pointer-events: none; }

.flickity-slider-wrap {
  overflow: hidden; }
  .flickity-slider-wrap.dots-inside .flickity-page-dots {
    position: absolute;
    bottom: 20px;
    margin-top: 0; }

.flickity-slider-wrap.dark-dots .flickity-page-dots .dot,
.owl-dark-dots .owl-page span {
  border-color: #7f7f7f; }

.flickity-slider-wrap.dark-dots .flickity-page-dots .dot.is-selected,
.owl-dark-dots .owl-page.active span {
  border-color: transparent; }

.owl-white-dots .owl-page.active span {
  background-color: #fff; }

.owl-carousel.dots-inside .owl-pagination {
  position: absolute;
  bottom: 20px;
  margin-top: 0; }

/*-------------------------------------------------------*/
/* Accordions and Toggles
/*-------------------------------------------------------*/
.accordion .panel-heading {
  position: relative;
  padding: 0;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .accordion .panel-heading:hover a {
    color: #e40115; }
  .accordion .panel-heading > a.minus {
    color: #000; }
  .accordion .panel-heading > a > span {
    display: block;
    width: 13px;
    height: 1px;
    background-color: #000;
    right: 18px;
    top: 24px;
    position: absolute; }
  .accordion .panel-heading > a.plus > span {
    background-color: #7f7f7f; }
  .accordion .panel-heading > a.plus > span:after {
    content: "";
    display: block;
    width: 13px;
    height: 1px;
    position: absolute;
    top: 0;
    background-color: #7f7f7f;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg); }

.accordion.panel-group .panel {
  border: none;
  box-shadow: none;
  margin-top: 5px; }
  .accordion.panel-group .panel:first-child {
    margin-top: 0; }

.panel-content p {
  margin-bottom: 0; }

.accordion .panel-heading + .panel-collapse > .panel-body,
.panel-content {
  border-top-color: transparent;
  padding: 20px 0;
  line-height: 24px;
  border-radius: 0; }

.accordion .panel-heading > a,
.toggle > .acc-panel > a {
  display: block;
  position: relative;
  text-decoration: none;
  padding: 14px 20px;
  color: #7f7f7f;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.accordion .panel-heading > a {
  border: 1px solid #e5e5e5; }

.toggle > .acc-panel > a {
  border-bottom: 1px solid #ebebeb;
  padding: 14px 0; }
  .toggle > .acc-panel > a:after {
    font-family: "Icon54com";
    position: absolute;
    content: "\ebd0";
    right: 0;
    margin-top: 2px;
    color: #7f7f7f;
    font-size: 12px; }
  .toggle > .acc-panel > a.active:after {
    content: "\f0aa";
    color: #000; }

.toggle .panel-content {
  padding: 20px 0 10px;
  border: none; }

/*-------------------------------------------------------*/
/* Tabs
/*-------------------------------------------------------*/
.nav-tabs {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: -1px; }

.nav.nav-tabs > li.active > a {
  border: 1px solid #ebebeb;
  background-color: #fff;
  border-bottom: 2px solid transparent; }

.nav.nav-tabs > li.active > a,
.nav.nav-tabs > li.active > a:hover,
.nav.nav-tabs > li.active > a:focus,
.nav.nav-tabs > li > a:hover,
.nav.nav-tabs > li > a:focus {
  color: #000; }

.nav-tabs > li {
  margin-bottom: -2px; }

.nav.nav-tabs > li > a {
  padding: 14px 20px 13px;
  background-color: #f7f8f9;
  margin-right: -1px;
  border: 1px solid #ebebeb;
  border-radius: 0;
  font-family: "Montserrat", sans-serif;
  color: #7f7f7f;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em; }

.tab-content {
  padding: 30px;
  border: 1px solid #ebebeb;
  overflow: hidden; }
  .tab-content > .tab-pane > p {
    margin-bottom: 0; }

/*-------------------------------------------------------*/
/* Buttons
/*-------------------------------------------------------*/
.section-buttons .btn {
  margin-bottom: 20px; }

.section-buttons [class^="col"] a:last-child {
  margin-bottom: 50px; }

.btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border: none;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  letter-spacing: 0.01em;
  text-transform: uppercase; }
  .btn:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .btn:after {
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background: #171717;
    content: '';
    position: absolute;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .btn:hover {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #fff; }
    .btn:hover:after {
      height: 100%; }
  .btn:hover span {
    color: #fff; }
  .btn:focus {
    color: #fff;
    outline: none !important; }
  .btn span {
    position: relative;
    z-index: 3;
    -webkit-transition: color 0.3s !important;
    -moz-transition: color 0.3s !important;
    -ms-transition: color 0.3s !important;
    -o-transition: color 0.3s !important;
    transition: color 0.3s !important; }
  .btn.btn-color:before {
    background-color: #e40115; }
  .btn.btn-color:after {
    background-color: #171717; }
  .btn.btn-transparent {
    background-color: transparent; }
    .btn.btn-transparent:before {
      border: 2px solid #fff; }
    .btn.btn-transparent:hover {
      border-color: transparent; }
    .btn.btn-transparent:hover span {
      color: #fff; }
  .btn.btn-light {
    color: #000; }
    .btn.btn-light:before {
      background-color: #f7f8f9; }
  .btn.btn-stroke {
    background-color: transparent;
    color: #000; }
    .btn.btn-stroke:before {
      border: 2px solid #ebebeb; }
    .btn.btn-stroke:hover {
      border-color: transparent; }
  .btn.btn-dark {
    color: #fff; }
    .btn.btn-dark:before {
      background-color: #171717; }
    .btn.btn-dark:after {
      background-color: #e40115; }
  .btn.btn-white {
    box-shadow: 1px 1.732px 3px 0px rgba(0, 0, 0, 0.05);
    color: #171717; }
    .btn.btn-white:before {
      background-color: #fff; }
    .btn.btn-white:after {
      background-color: #313131; }
  .btn.btn-pink:before {
    background-color: #d77592; }
  .btn.btn-violet:before {
    background-color: #b993c9; }
  .btn.btn-green:before {
    background-color: #39b378; }
  .btn.btn-orange:before {
    background-color: #e6965b; }
  .btn.btn-blue:before {
    background-color: #57b3c6; }

.btn-lg {
  font-size: 12px;
  padding: 0 35px; }
  .btn-lg span {
    line-height: 44px; }

.btn-md {
  font-size: 11px;
  padding: 0 30px; }
  .btn-md span {
    line-height: 38px; }

.btn-sm {
  font-size: 10px;
  padding: 0 20px; }
  .btn-sm span {
    line-height: 34px; }

.rounded,
.rounded:before {
  border-radius: 70px; }

/*-------------------------------------------------------*/
/* Social Icons
/*-------------------------------------------------------*/
.social-icons a {
  margin: 0 3px 6px 0;
  display: inline-block;
  width: 32px;
  height: 32px;
  color: #7f7f7f;
  border: 1px solid #d9d9d9;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }
  .social-icons a:last-child {
    margin-right: 0 !important; }
  .social-icons a:hover {
    background-color: #e40115;
    border-color: transparent;
    color: #fff; }

.social-icons.nobase a {
  width: auto;
  height: auto;
  margin-right: 14px;
  background-color: transparent;
  border: none; }
  .social-icons.nobase a:hover {
    background-color: transparent !important;
    color: #000; }

.rounded a {
  border-radius: 50%; }

/*-------------------------------------------------------*/
/* Form Elements
/*-------------------------------------------------------*/
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="search"],
select,
textarea {
  height: 44px;
  border: 1px solid #dedede;
  background-color: transparent;
  width: 100%;
  margin-bottom: 30px;
  font-size: 15px;
  padding: 0 16px;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

textarea {
  height: auto;
  padding: 8px 16px;
  margin-bottom: 25px; }

input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
textarea:focus {
  border-color: #e40115;
  background-color: #fff;
  outline: none;
  box-shadow: none !important; }

input:not([type=checkbox]):not([type=radio]),
textarea {
  -webkit-appearance: none; }

/* Change Color of Placeholders */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #7f7f7f; }

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #7f7f7f;
  opacity: 1; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #7f7f7f;
  opacity: 1; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #7f7f7f; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding-left: 12px;
  background-image: url(../img/dropdown.png);
  background-repeat: no-repeat;
  background-position: 100% 50%; }

select::-ms-expand {
  display: none; }

.row-12 input[type="text"],
.row-12 input[type="email"] {
  margin-bottom: 12px; }

/* Checkboxes & Radio Buttons
-------------------------------------------------------*/
input[type="checkbox"] {
  display: none; }

input[type="checkbox"] + label:before {
  width: 17px;
  height: 17px;
  content: "";
  display: inline-block;
  font-size: 13px;
  margin: -4px 12px 0 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #dedede; }

input[type="checkbox"]:checked + label:before {
  content: "\f0c8";
  font-family: "FontAwesome";
  color: #000;
  font-size: 9px;
  line-height: 16px; }

input[type="checkbox"] + label,
input[type="radio"] + label {
  cursor: pointer;
  margin-bottom: 0;
  text-transform: none;
  letter-spacing: normal;
  color: #000;
  font-size: 15px; }

.radio-buttons > li,
.checkboxes > li {
  padding: 7px 0; }

input[type="radio"] {
  display: none; }

input[type="radio"] + label:before {
  display: inline-block;
  content: "";
  width: 17px;
  height: 17px;
  box-shadow: 0 0 0 1px #d4d4d4;
  border-radius: 40px;
  margin: -3px 10px 0 0;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0; }

input[type="radio"]:checked + label:before {
  background-color: #171717;
  border: 5px solid #fff;
  padding: 1px; }

input[type="radio"]:focus {
  outline: none; }

label {
  color: #000;
  font-size: 15px;
  vertical-align: middle;
  font-weight: 400;
  margin-bottom: 7px; }

input.btn.btn-lg,
button.btn.btn-lg {
  padding: 0 34px;
  height: 44px;
  font-size: 12px; }

input.btn.btn-md,
button.btn.btn-md {
  padding: 0 27px;
  height: 38px; }

input.btn:hover,
button.btn:hover {
  background-color: #c00112;
  color: #fff; }

input.btn.btn-dark,
button.btn.btn-dark {
  background-color: #000; }
  input.btn.btn-dark:hover,
  button.btn.btn-dark:hover {
    background-color: #e40115; }

input.btn.btn-stroke,
button.btn.btn-stroke {
  border: 2px solid #ebebeb; }
  input.btn.btn-stroke:hover,
  button.btn.btn-stroke:hover {
    background-color: #e40115; }

/*-------------------------------------------------------*/
/* Lightboxes
/*-------------------------------------------------------*/
.mfp-iframe-holder .mfp-content {
  max-width: 1400px; }

.modal-body,
.modal-footer {
  padding: 30px; }

.modal-header {
  padding: 15px 30px; }

/*-------------------------------------------------------*/
/* Tables
/*-------------------------------------------------------*/
table th {
  font-weight: normal; }

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid #ebebeb;
  padding: 10px; }

.table > thead > tr > th {
  border-bottom: none; }

.table thead tr th {
  font-size: 14px; }

.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ebebeb; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f7f8f9; }

/*-------------------------------------------------------*/
/* Alert Boxes
/*-------------------------------------------------------*/
.alert {
  padding: 14px 18px;
  margin-bottom: 20px;
  border: none;
  color: #fff;
  border-radius: 3px; }
  .alert strong {
    color: inherit; }

.alert-dismissible .close {
  right: 0;
  top: 0;
  color: inherit;
  position: relative; }

.close {
  opacity: 1;
  text-shadow: none;
  font-weight: normal; }

.modal-header .close {
  font-size: 25px;
  margin-top: 2px !important; }

.alert-success {
  background-color: #dbfaeb;
  color: #158f54; }

.alert-info {
  background-color: #dbeef2;
  color: #18869d; }

.alert-warning {
  background-color: #f2ebd3;
  color: #916c46; }

.alert-danger {
  background-color: #f9e0de;
  color: #a75a54; }

/*-------------------------------------------------------*/
/* Products
/*-------------------------------------------------------*/
.product-item {
  margin-bottom: 30px; }

.product-img img {
  overflow: hidden;
  min-width: 99.9%; }

.product-img > a {
  display: block;
  position: relative; }

.product-label {
  position: absolute;
  top: 10px;
  left: 10px;
  pointer-events: none; }
  .product-label > span {
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    padding: 3px 7px;
    letter-spacing: 0.05em;
    border-radius: 3px; }
  .product-label .sale {
    color: #fff;
    background-color: #e95e49; }

.sold-out {
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  pointer-events: none;
  padding: 15px 0;
  display: block;
  text-align: center;
  width: 100%;
  z-index: 5;
  text-transform: uppercase;
  font-weight: bold;
  cursor: default;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.05em; }

.product-details {
  position: relative;
  float: left;
  max-width: 65%; }

.product-item .price {
  float: right; }

.price del {
  color: #7f7f7f;
  font-size: 13px;
  display: block;
  margin-bottom: 5px; }

.price ins {
  text-decoration: none;
  color: #000;
  font-weight: 600;
  font-size: 13px; }

.hover-overlay .product-title a,
.hover-overlay .price del,
.hover-overlay .price ins,
.hover-overlay .category a {
  color: #fff; }

.hover-overlay .price del {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0; }

.hover-overlay .product-details {
  text-align: center;
  float: none;
  max-width: none; }

.hover-overlay .product-details > h3 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-top: 10px; }

.hover-overlay .price {
  float: none; }

.hover-overlay .btn-quickview {
  margin-top: 24px; }

.product-details > h3,
.product-list-widget a > span,
.table.shop_table tr td.product-name a {
  line-height: 14px;
  margin-bottom: 5px;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400; }

.product-title a,
.product-list-widget a {
  line-height: 1.5;
  color: #000; }
  .product-title a:hover,
  .product-list-widget a:hover {
    color: #e40115; }

.category a {
  font-size: 13px;
  color: #7f7f7f; }
  .category a:hover {
    color: #e40115; }

.hover-2 .product-actions .product-add-to-wishlist {
  color: #7f7f7f; }

.product-img {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px; }
  .product-img:hover .product-actions {
    opacity: 1;
    visibility: visible; }
  .product-img:hover .product-quickview {
    bottom: 0; }
  .product-img .product-quickview {
    position: absolute;
    bottom: -36px;
    width: 100%;
    background-color: #171717;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-align: center;
    line-height: 35px;
    color: #fff !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .product-img .product-quickview:hover {
      background-color: #e40115; }
  .product-img .back-img {
    position: absolute;
    top: 0;
    opacity: 0;
    visibility: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .product-img:hover .back-img {
    opacity: 1;
    visibility: visible; }

.product-img .product-actions {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0;
  line-height: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .product-img .product-actions a {
    color: #fff;
    display: inline-block;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .product-img .product-actions a:hover {
      color: #e40115; }
  .product-img .product-actions i {
    font-size: 13px; }

.rating {
  font-size: 13px;
  line-height: 26px; }
  .rating:before {
    font-family: 'FontAwesome';
    content: '\f005 \f005 \f005 \f005 \f006';
    letter-spacing: 0.25em;
    font-size: 12px;
    color: #a9946d;
    font-style: normal; }
  .rating a {
    color: #a3a3a3;
    margin-left: 7px; }

/*-------------------------------------------------------*/
/* Promo Banners / Collection
/*-------------------------------------------------------*/
.promo-banner a {
  display: block;
  position: relative;
  overflow: hidden; }

.promo-banner img {
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  backface-visibility: hidded;
  -webkit-backface-visibility: hidden; }

.promo-banner a:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1); }

.promo-inner {
  position: absolute;
  width: 100%;
  text-align: center; }
  .promo-inner span {
    color: #fff;
    font-size: 18px;
    font-weight: 600; }
  .promo-inner h2 {
    color: #fff;
    font-size: 32px; }
    @media only screen and (max-width: 991px) {
      .promo-inner h2 {
        font-size: 20px; } }

.promo-banner.style-2 .promo-inner {
  bottom: 15px;
  left: 15px;
  right: 15px;
  width: auto;
  background-color: #fff; }
  .promo-banner.style-2 .promo-inner h2,
  .promo-banner.style-2 .promo-inner span {
    color: #000; }
  .promo-banner.style-2 .promo-inner h2 {
    margin-bottom: 0;
    font-size: 18px;
    padding: 25px 10px; }

/*-------------------------------------------------------*/
/* Product tabs with slider
/*-------------------------------------------------------*/
.tabs-slider-content {
  padding: 0;
  border: none; }
  .tabs-slider-content .product {
    padding: 0 10px; }
  .tabs-slider-content > .tab-pane {
    display: block !important;
    height: 0; }
  .tabs-slider-content > .tab-pane.active {
    height: auto; }
  .tabs-slider-content .tab-pane .owl-carousel {
    visibility: hidden; }
  .tabs-slider-content .tab-pane.active .owl-carousel {
    visibility: visible; }

.product-tabs {
  margin-bottom: 40px;
  display: inline-block;
  position: relative; }
  .product-tabs .nav.nav-tabs > li.active:before {
    display: none; }

.product-tabs .nav.nav-tabs {
  border: none; }

.product-tabs .nav.nav-tabs > li.active > a {
  border-bottom: 2px solid #e40115;
  background-color: transparent;
  padding: 0;
  color: #000; }

.product-tabs .nav.nav-tabs > li > a {
  padding: 0;
  border: none;
  background-color: transparent;
  color: #7f7f7f;
  font-size: 18px;
  font-weight: 400; }
  .product-tabs .nav.nav-tabs > li > a:hover {
    color: #000; }

.product-tabs .nav.nav-tabs > li {
  margin: 0 12px 10px 12px; }

/*-------------------------------------------------------*/
/* Promo Section
/*-------------------------------------------------------*/
.promo-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  height: 840px; }
  .promo-bg.overlay:before {
    background-color: rgba(0, 0, 0, 0.03); }
  .promo-bg .container {
    display: table;
    height: 100%; }
  .promo-bg .table-box {
    display: table-cell;
    vertical-align: middle;
    position: relative; }
  .promo-bg h2 {
    font-size: 80px; }
    @media only screen and (max-width: 767px) {
      .promo-bg h2 {
        font-size: 48px; } }
  .promo-bg p {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px; }

h2.heading-frame {
  font-size: 72px;
  border: 10px solid #fff;
  line-height: 1;
  padding: 30px 63px;
  display: inline-block; }

/*-------------------------------------------------------*/
/* Product Grid (Demo 3)
/*-------------------------------------------------------*/
.products-grid-wrap {
  padding: 20px 0 20px 20px;
  width: 100%; }

#products-grid .product-item {
  margin-bottom: 0;
  padding: 0 20px 20px 0;
  width: 25%; }

#products-grid .product-img {
  margin-bottom: 0; }

/*-------------------------------------------------------*/
/* Catalogue
/*-------------------------------------------------------*/
/* Filter
-------------------------------------------------------*/
.result-count {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 8px; }

.ecommerce-ordering {
  float: right;
  width: 23%; }
  .ecommerce-ordering select {
    margin-bottom: 0;
    padding: 0;
    height: 30px;
    border: none;
    background-color: #fff; }

.shop-filter,
.view-mode {
  float: left; }

.shop-filter {
  padding-bottom: 14px;
  margin-bottom: 50px;
  border-bottom: 1px solid #ebebeb;
  width: 100%; }

.shop-filter .view-mode,
.shop-filter .filter-show {
  margin-top: 5px; }

.view-mode .grid,
.view-mode .list {
  background: url(../img/grid_list_icons.png) 0 0 no-repeat;
  display: inline-block;
  width: 13px;
  height: 13px;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 2px;
  vertical-align: middle;
  opacity: .5; }

.view-mode .grid.grid-active,
.view-mode .list.list-active {
  opacity: 1; }

.view-mode .grid {
  background-position: 0 0; }

.view-mode .list {
  background-position: 0 -30px; }

.filter-show {
  margin-left: 40px;
  float: left; }
  .filter-show a {
    color: #7f7f7f; }
    .filter-show a.active {
      color: #000; }
    .filter-show a:last-child:after {
      display: none; }
    .filter-show a:after {
      content: '/';
      margin: 0 2px 0 6px;
      color: #7f7f7f; }

/* List View
-------------------------------------------------------*/
.list-view .product-list {
  width: 100%; }

.product-grid .product-description {
  display: none; }

.product-list .product-img {
  width: 32%;
  float: left;
  margin-bottom: 0;
  margin-right: 3%; }

.product-list .product-item {
  float: left;
  width: 100%; }

.product-list .product-description {
  float: left;
  width: 65%;
  margin-top: 20px; }

.product-list .product-title {
  line-height: 14px;
  margin-bottom: 15px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif; }

.product-list .product-details,
.product-list .price,
.product-list .product-actions .product-add-to-wishlist {
  display: none; }

.product-list .price {
  float: left;
  margin-right: 18px; }

.product-list .product-add-to-wishlist {
  display: inline-block;
  width: 39px;
  height: 39px;
  margin-left: 10px;
  line-height: 39px;
  text-align: center; }

.product-list .product-add-to-wishlist a {
  color: #000;
  background-color: #eeeeee;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .product-list .product-add-to-wishlist a:hover {
    background-color: #e40115;
    color: #fff; }

.product-description .price {
  display: block; }

.product-description p {
  margin: 20px 0; }

.product-list .price del,
.single-product .price del {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 18px; }

.product-list .price ins,
.single-product .price ins {
  display: inline-block;
  font-size: 18px; }

/* Product List Widget
-------------------------------------------------------*/
.product-list-widget img {
  width: 84px;
  float: left;
  margin-right: 20px;
  display: inline-block; }

.product-list-widget li {
  position: relative;
  overflow: hidden;
  padding: 20px 0 !important;
  border-bottom: 1px solid #ebebeb; }
  .product-list-widget li:first-child {
    padding-top: 0 !important; }
  .product-list-widget li:last-child {
    padding-bottom: 0 !important;
    border: 0 !important; }

.product-list-widget .product-title {
  display: block;
  margin-bottom: 5px; }

/* Price Slider
-------------------------------------------------------*/
.ui-slider-horizontal {
  cursor: pointer;
  position: relative;
  height: 7px;
  background: #ddd;
  margin-bottom: 20px;
  border-radius: 1px; }

.filter-by-price .ui-slider .ui-slider-handle {
  position: absolute;
  width: 13px;
  height: 13px;
  background: #000;
  top: -3px;
  margin-left: -13px; }

.filter-by-price .ui-slider span:first-of-type {
  margin-left: 0; }

.ui-slider .ui-slider-range {
  border-radius: 10px;
  position: absolute;
  background-color: #e40115;
  height: 7px; }

.filter-by-price p {
  margin-bottom: 0; }

.filter-by-price input#amount {
  width: 40%;
  padding: 0;
  font-size: 14px;
  color: #000;
  border: none;
  background-color: transparent;
  height: auto;
  border-radius: 0;
  margin-bottom: 0;
  box-shadow: none;
  line-height: 1;
  vertical-align: middle; }

.filter-by-price label {
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  line-height: 33px;
  margin-bottom: 0;
  font-size: 14px;
  text-transform: none;
  letter-spacing: normal;
  color: #7f7f7f; }

.filter-by-price p > a {
  float: right;
  padding: 0 15px; }

.price-filter {
  margin-top: 7px;
  float: left; }

/*-------------------------------------------------------*/
/* Single Product
/*-------------------------------------------------------*/
.product-slider {
  padding-right: 50px; }

.product-description-wrap {
  padding-left: 50px; }

@media only screen and (max-width: 991px) {
  .product-slider,
  .product-description-wrap {
    padding: 0 15px; } }

header + hr {
  margin: 0;
  border-color: #ebebeb; }

/* Image slider
/*-------------------------------------------------------*/
#gallery-main .gallery-cell {
  margin-bottom: 10px; }

.gallery-cell,
#gallery-main img {
  width: 100%; }

.gallery-thumbs {
  margin: 0 -5px; }
  .gallery-thumbs .gallery-cell {
    width: 20%;
    float: left;
    padding: 0 5px; }
  .gallery-thumbs img {
    opacity: 0.5;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
  .gallery-thumbs .is-nav-selected img,
  .gallery-thumbs img:hover {
    opacity: 1; }

.zoom-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 24px;
  color: #7f7f7f;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s; }
  .zoom-icon:hover {
    color: #000; }

.single-product .price {
  margin-right: 20px;
  display: inline-block; }

.single-product .product-actions {
  margin: 40px 0 24px;
  display: flex;
  align-items: center; }

.single-product .product-add-to-wishlist {
  width: 44px;
  height: 44px;
  line-height: 44px;
  background-color: #eee;
  text-align: center;
  margin-left: 20px;
  color: #000;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .single-product .product-add-to-wishlist:hover {
    background-color: #e40115;
    color: #fff; }

.single-product .product_meta {
  margin: 4px 0 30px; }

.product-description-wrap .breadcrumb {
  padding-top: 0;
  margin-bottom: 35px; }

.product-description-wrap h1 {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding-bottom: 24px;
  margin-bottom: 44px;
  border-bottom: 1px solid #ebebeb; }

.product-description-wrap .short-description {
  margin-top: 30px; }

/* Color Swatches / Size Options
-------------------------------------------------------*/
.widget.filter-by-color label,
.widget.filter-by-size label {
  color: #7f7f7f; }

.color-swatches {
  margin: 40px 0; }

.color-swatches,
.size-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center; }

.color-swatches a,
.size-options a {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-right: 9px; }

.color-swatches span,
.size-options span,
.product-actions > span {
  width: 70px;
  color: #000;
  font-size: 13px;
  font-weight: 600; }

.swatch-violet {
  background-color: #8da1cd; }

.swatch-black {
  background-color: #000; }

.swatch-cream {
  background-color: #e6e2d6; }

.size-options a {
  background-color: #eee;
  color: #7f7f7f;
  text-align: center;
  line-height: 36px;
  font-size: 12px; }

/* Quantity
-------------------------------------------------------*/
.quantity.buttons_added {
  white-space: nowrap;
  position: relative;
  margin-right: 20px; }
  .quantity.buttons_added .quantity-adjust {
    float: left; }
  .quantity.buttons_added .minus,
  .quantity.buttons_added .plus {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    display: block;
    background-color: #fff;
    border: 1px solid #ebebeb;
    border-left: none;
    text-align: center;
    color: #000; }
  .quantity.buttons_added .plus {
    border-bottom: none; }

.quantity .input-text.qty {
  width: 44px;
  height: 44px;
  padding: 0;
  margin-bottom: 0;
  text-align: center;
  float: left;
  border: 1px solid #ebebeb;
  background-color: transparent;
  line-height: 1;
  border-radius: 0; }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.product_meta span {
  display: block;
  line-height: 32px;
  font-size: 13px;
  color: #7f7f7f; }

.product_meta span a {
  color: #000; }
  .product_meta span a:hover {
    color: #e40115; }

.single-product .socials-share span,
.project-info .socials-share span {
  color: #7f7f7f;
  font-size: 13px; }

.socials-share .social-icons {
  display: inline-block;
  margin-left: 18px; }
  .socials-share .social-icons a {
    color: #b2b2b2;
    font-size: 13px; }
    .socials-share .social-icons a:hover {
      color: #000; }

/* Tabs
/*-------------------------------------------------------*/
.single-product .shop_attributes {
  margin-bottom: 0; }
  .single-product .shop_attributes tr th {
    padding-left: 0 !important; }
  .single-product .shop_attributes tr td {
    padding-right: 0 !important;
    text-align: right; }
  .single-product .shop_attributes tr:first-child th,
  .single-product .shop_attributes tr:first-child td {
    border-top: none !important; }

.single-product .reviews-lists li {
  margin-top: 20px; }
  .single-product .reviews-lists li:first-child {
    margin-top: 0; }

.single-product .review-content p:first-child {
  color: #000;
  margin-bottom: 0; }

.review-body {
  padding: 12px 0; }

#owl-related-items .product-item {
  padding: 0 15px;
  margin-bottom: 0; }

/*-------------------------------------------------------*/
/* Cart Page
/*-------------------------------------------------------*/
.shopping-cart h2,
.checkout h2 {
  font-size: 16px; }

.order-review-wrap {
  border: 1px solid #dedede;
  padding: 40px 30px; }

.cart_totals h2,
.order-review-wrap h2 {
  margin-bottom: 15px; }

.shop_table thead {
  border-bottom: 1px solid #ebebeb; }

.shop_table tr td {
  vertical-align: middle !important; }

.shop_table.cart {
  margin-bottom: 0; }

.shop_table.cart thead tr th,
.shop_table.cart tr {
  padding: 14px 0;
  border-left: none; }

.shop_table.cart tr:last-child {
  border-bottom: 1px solid #ebebeb; }

.shop_table .product-thumbnail {
  padding: 30px 24px 30px 0;
  width: 130px;
  max-width: 130px;
  min-width: 80px; }
  @media only screen and (max-width: 991px) {
    .shop_table .product-thumbnail {
      padding: 15px; } }

.shop_table td.product-name {
  width: 30%;
  line-height: 24px; }

.shop_table td.product-quantity {
  min-width: 132px; }

.shop_table .product-name > a,
.shop_table .product-price > span,
.shop_table .product-subtotal > span {
  color: #000; }

.shop_table .product-name > a:hover {
  color: #e40115; }

.shop_table .product-remove {
  text-align: right;
  min-width: 30px; }

.remove {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #f7f8f9;
  color: #7f7f7f; }
  .remove i {
    font-size: 18px;
    display: block; }
  .remove:hover {
    background-color: #e40115;
    color: #fff; }

.cart_totals {
  padding-left: 30px; }
  @media only screen and (max-width: 991px) {
    .cart_totals {
      padding-left: 0; } }

.cart_totals table {
  margin-bottom: 15px;
  min-width: 100%; }

.cart_totals .table > tbody > tr > td,
.cart_totals .table > tbody > tr > th,
.table.ecommerce-checkout-review-order-table > tbody > tr > td,
.table.ecommerce-checkout-review-order-table > tbody > tr > th {
  color: #000;
  padding: 14px 0; }

.cart_totals tr:first-child th,
.cart_totals tr:first-child td,
.ecommerce-checkout-review-order-table tr:first-child th,
.ecommerce-checkout-review-order-table tr:first-child td {
  border-top: 0; }

.cart_totals tr td,
.ecommerce-checkout-review-order tr td {
  text-align: right; }

.order-total .amount {
  font-size: 16px; }

.actions {
  float: right; }
  @media only screen and (max-width: 991px) {
    .actions {
      float: left; } }

.actions > input,
.actions > div {
  display: inline-block;
  margin-left: 5px; }

input.btn,
button.btn {
  padding: 12px 27px;
  font-size: 11px;
  background-color: #424242; }

.coupon .input-text {
  width: 50%;
  display: inline-block;
  float: left;
  margin-right: 8px; }

.form-row {
  float: left; }
  .form-row.form-row-wide {
    width: 100%; }

.shipping-calculator-form p:last-child,
select.country_to_state {
  margin-bottom: 0; }

#calc_shipping_postcode,
#calc_shipping_state {
  margin-bottom: 10px; }

.table.shop_table tr td.product-name {
  line-height: 24px; }

/*-------------------------------------------------------*/
/* Checkout
/*-------------------------------------------------------*/
#customer_details {
  padding-right: 30px; }
  @media only screen and (max-width: 991px) {
    #customer_details {
      padding-right: 15px; } }

.ecommerce form #customer_details p {
  display: block;
  width: 100%;
  margin-bottom: 20px; }

.ecommerce form .form-row:after {
  clear: both; }

.ecommerce form #customer_details .form-row input,
.ecommerce form #customer_details .form-row select,
.ecommerce form #customer_details .form-row textarea {
  margin-bottom: 0; }

.ecommerce form #customer_details .form-row textarea {
  min-height: 120px;
  line-height: 20px; }

.ecommerce form #customer_details label {
  float: left;
  width: 20%;
  line-height: 39px;
  margin: 0; }
  @media only screen and (max-width: 767px) {
    .ecommerce form #customer_details label {
      width: 100%; } }

.ecommerce .checkout #createaccount,
.ecommerce .checkout #ship-to-different-address-checkbox {
  float: none !important;
  width: auto !important; }

.ecommerce .checkout label.checkbox {
  float: none !important;
  width: auto !important;
  line-height: 1.5 !important; }

.ecommerce .create-account {
  margin-top: 30px; }

.ecommerce .form-row.place-order {
  float: none;
  margin-top: 30px; }

abbr.required {
  color: #f34937; }

.ecommerce form #customer_details .form-row input,
.ecommerce form #customer_details .form-row select,
.ecommerce form #customer_details .form-row textarea {
  float: right;
  width: 80%;
  margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .ecommerce form #customer_details .form-row input,
    .ecommerce form #customer_details .form-row select,
    .ecommerce form #customer_details .form-row textarea {
      float: none;
      width: 100%; } }

abbr.required {
  color: #e7604a; }

#billing_address_1_field {
  margin-bottom: 10px !important; }

.ecommerce-shipping-fields {
  margin-bottom: 50px; }

.order-review-wrap table {
  margin-bottom: 40px; }

.ecommerce-checkout-review-order-table .order-total {
  font-size: 15px; }

.order-review .payment_methods.methods li {
  padding-bottom: 10px; }

.form-row.place-order input {
  width: 100%; }

.payment_methods li {
  padding: 15px 0;
  border-bottom: 1px solid #ebebeb; }

.payment_method_paypal img {
  margin-left: 15px; }

.payment_box p {
  margin-top: 10px;
  margin-bottom: 0; }

.payment_methods label {
  color: #000;
  vertical-align: middle;
  margin-bottom: 0; }

/*-------------------------------------------------------*/
/* Footer Type-1
/*-------------------------------------------------------*/
.footer-widgets {
  padding: 80px 0; }
  .footer-widgets .widget-title {
    margin-bottom: 26px;
    font-size: 16px;
    position: relative;
    text-transform: none;
    letter-spacing: normal; }
  .footer-widgets a {
    color: #7f7f7f; }
    .footer-widgets a:hover {
      color: #000; }

.footer-about-us p {
  font-size: 13px;
  line-height: 28px;
  margin-top: 43px; }

.footer-get-in-touch p,
.footer-get-in-touch a {
  line-height: 31px;
  margin-bottom: 0;
  padding-bottom: 0; }

.footer-address {
  line-height: 24px;
  margin-bottom: 20px; }

.entry-li:first-child,
.footer-links li:first-child {
  padding-top: 0 !important; }

.entry-li:last-child,
.footer-links li:last-child {
  border-bottom: none !important;
  padding-bottom: 0 !important; }

.footer-links ul > li {
  font-size: 13px; }

.footer-payment-systems i {
  font-size: 26px;
  vertical-align: middle;
  line-height: 32px; }

.bottom-footer {
  padding: 23px 0;
  border-top: 1px solid #ebebeb; }
  .bottom-footer .footer-socials {
    margin-top: 0; }
  .bottom-footer .social-icons a {
    background-color: transparent;
    margin-bottom: 0; }
    .bottom-footer .social-icons a:hover {
      background-color: transparent !important; }

.copyright span {
  display: inline-block;
  font-size: 13px;
  line-height: 32px; }

.copyright a {
  color: #7f7f7f; }

.SingleDatePicker_picker {
  z-index: 102 !important; }

.DateInput_input {
  border: none; }

.DateInput--disabled {
  background: #faffe3; }

.clear {
  clear: both; }

.navbar-static-top {
  z-index: 9999; }

.cart-actions {
  padding: 30px 0; }

.modal-footer > a {
  margin-right: 15px; }

.home-top-categories .container .row div h3 {
  font-size: 18px; }
  .home-top-categories .container .row div h3:last-child {
    margin-top: 20px; }

.home-top-categories .container .row div ul {
  margin-bottom: 20px; }
  .home-top-categories .container .row div ul li {
    color: #333;
    font-size: 12px; }
    .home-top-categories .container .row div ul li a {
      color: #333;
      font-size: 12px; }

ul.top-categories a {
  color: #7f7f7f; }

footer h4 {
  font-size: 16px;
  font-weight: 400; }

footer ul li {
  color: #333;
  font-size: 12px; }
  footer ul li a {
    color: #333;
    font-size: 12px; }

footer .footer-social li a i {
  display: inline-block;
  width: 15px; }

footer .footer-social .ion-social-rss {
  color: #e40115; }

footer .footer-social .ion-social-facebook {
  color: #3b5998; }

footer .footer-social .ion-social-twitter {
  color: #0084b4; }

footer .footer-social .ion-social-instagram-outline {
  color: #cd486b; }

footer .nos-partenaires li {
  margin: 0 0 10px 0; }
  footer .nos-partenaires li img {
    height: 20px;
    opacity: 0.42;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=42)";
    filter: alpha(opacity=42);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 200ms ease-in-out; }
    footer .nos-partenaires li img:hover {
      -webkit-filter: grayscale(0);
      -moz-filter: grayscale(0);
      filter: grayscale(0);
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }
  footer .nos-partenaires li:last-child {
    margin-bottom: 0;
    margin-top: 5px; }

.how-to-blocs .row i {
  color: #e40115;
  font-size: 2.5em; }

.how-to-blocs .row .media-body {
  vertical-align: middle; }

.trust-pilote {
  margin-bottom: 30px; }

.badge.badge-success {
  background: green;
  display: inline-block;
  padding: 3px 5px;
  font-size: 14px;
  font-weight: bold;
  color: #fff; }

.pt-0 {
  padding-top: 0; }

.single-product-wrapper .ais-InstantSearch__root .left-sidebar {
  float: none; }

.ais-horizontal-menu .ais-Menu__root {
  max-width: 100%; }

.ais-horizontal-menu .ais-Menu__items {
  float: none;
  text-align: center;
  border-top: 1px #e5e5e5 solid;
  border-bottom: 1px #e5e5e5 solid; }
  .ais-horizontal-menu .ais-Menu__items .ais-Menu__item {
    display: inline-block;
    cursor: pointer;
    width: auto;
    padding: 15px 0; }
    .ais-horizontal-menu .ais-Menu__items .ais-Menu__item a {
      display: block;
      height: 25px;
      line-height: 25px;
      padding: 0 10px;
      color: #222;
      font-size: 14px; }
    .ais-horizontal-menu .ais-Menu__items .ais-Menu__item:hover, .ais-horizontal-menu .ais-Menu__items .ais-Menu__item:focus {
      background-color: #222; }
      .ais-horizontal-menu .ais-Menu__items .ais-Menu__item:hover a, .ais-horizontal-menu .ais-Menu__items .ais-Menu__item:focus a {
        color: #fff; }

.ais-horizontal-menu.ais-horizontal--no-badges .ais-Menu__itemCount {
  display: none; }

.call-center {
  background-color: whitesmoke;
  margin-top: 30px;
  padding: 20px; }
  .call-center h2 {
    color: #222;
    font-size: 14px;
    padding: 0;
    margin: 0 0 20px 0;
    text-align: center; }
  .call-center .call-center--container {
    background-color: #fff;
    position: relative;
    padding: 10px 10px 10px 50px; }
    .call-center .call-center--container p {
      color: #000;
      margin: 0; }
    .call-center .call-center--container:before {
      background: url("../img/sprite-hotline.png") no-repeat 0 0;
      content: "";
      display: block;
      width: 89px;
      height: 89px;
      position: absolute;
      left: -50px;
      top: 2px;
      border-radius: 50%; }
    .call-center .call-center--container > div {
      min-height: 63px;
      line-height: 63px; }
    .call-center .call-center--container .company-logo .img-responsive {
      display: inline-block;
      max-width: 140px;
      width: 100%; }
    .call-center .call-center--container .company-phone-number p {
      font-weight: bold;
      font-size: 24px; }
      .call-center .call-center--container .company-phone-number p:before {
        background: url("/img/sprite-hotline.png") no-repeat -89px 0;
        border-radius: 50%;
        content: "";
        display: inline-block;
        font-size: 0;
        height: 42px;
        margin-right: 9px;
        position: relative;
        top: 10px;
        width: 42px; }
    .call-center .call-center--container .company-times p {
      padding-top: 9px; }
      .call-center .call-center--container .company-times p span {
        display: block; }

.icon-container {
  margin-bottom: 15px;
  padding: 12px 0px; }

.follow-us {
  margin-top: 30px;
  text-align: right; }
  .follow-us span {
    font-size: 16px;
    color: #000; }
  .follow-us a {
    display: inline-block;
    width: 35px;
    height: 35px;
    background-color: #e40115;
    border-radius: 50%;
    margin-left: 8px;
    text-align: center;
    line-height: 38px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    transition: all 200ms ease-in-out; }
    .follow-us a i {
      font-size: 18px;
      color: #fff; }
    .follow-us a.btn-facebook {
      background-color: #3b5998; }
    .follow-us a.btn-twitter {
      background-color: #0084b4; }
    .follow-us a.btn-instagram {
      background-color: #cd486b; }
    .follow-us a:hover, .follow-us a:focus {
      opacity: 0.9;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
      filter: alpha(opacity=90); }

.how-to-blocs-more {
  border-top: 1px whitesmoke solite; }
  .how-to-blocs-more > div {
    text-align: center; }
    .how-to-blocs-more > div i {
      font-size: 4em;
      color: #e40115;
      display: inline-block;
      margin-bottom: 15px; }
    .how-to-blocs-more > div p {
      font-size: 18px;
      text-align: center;
      min-height: 72px;
      color: #111; }

.badge-icon {
  color: #222; }
  .badge-icon i {
    color: green; }

.parlent-de-nous {
  text-align: center; }
  .parlent-de-nous img {
    height: 30px;
    margin: 0 15px;
    opacity: 0.42;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=42)";
    filter: alpha(opacity=42);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 200ms ease-in-out; }
    .parlent-de-nous img:first-child {
      margin-left: 0; }
    .parlent-de-nous img:last-child {
      margin-right: 0; }

button.btn:after {
  background: transparent !important;
  display: none; }

.has-error .form-control {
  margin-bottom: 0; }

.product-add-to-cart > div:last-child {
  padding-top: 30px; }

.row-eq-height > div, .row-same-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .row-eq-height > div [class*="col-"], .row-eq-height > div .col-eq-height, .row-same-height [class*="col-"], .row-same-height .col-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }

.product-actions > form {
  width: 100%; }
  .product-actions > form .form-group > div .dateContainer.emptyField .datepickup {
    width: 100%; }
    .product-actions > form .form-group > div .dateContainer.emptyField .datepickup .SingleDatePicker .SingleDatePickerInput {
      border-right: 1px solid #dbdbdb; }
  .product-actions > form .form-group > div .dateContainer > div {
    padding: 0; }
  .product-actions > form .form-group > div .dateContainer:first-child {
    padding: 0 10px 0 0; }
  .product-actions > form .form-group > div .dateContainer:last-child {
    padding: 0 0 0 10px; }
  .product-actions > form .form-group .datepickup {
    padding-right: 0; }
    .product-actions > form .form-group .datepickup .SingleDatePicker {
      width: 100%; }
      .product-actions > form .form-group .datepickup .SingleDatePicker .SingleDatePickerInput {
        border-right: none;
        width: 100%; }
        .product-actions > form .form-group .datepickup .SingleDatePicker .SingleDatePickerInput .DateInput_input {
          border: none; }
        .product-actions > form .form-group .datepickup .SingleDatePicker .SingleDatePickerInput .DateInput {
          width: 100%;
          height: 48px;
          padding: 0; }
          .product-actions > form .form-group .datepickup .SingleDatePicker .SingleDatePickerInput .DateInput > div {
            height: 48px;
            line-height: 48px;
            padding: 0 8px; }
  .product-actions > form .form-group .hourpickup {
    padding-left: 0;
    height: 50px; }
    .product-actions > form .form-group .hourpickup select {
      width: 100%;
      font-size: 18px;
      height: 50px;
      line-height: 50px;
      color: #555555; }

.soit-amount {
  padding-left: 0; }

select.country_to_state {
  width: 150px; }

.faq-blocs {
  background-color: #f7f7f7;
  padding: 15px 0; }
  .faq-blocs .row {
    padding: 15px 0; }
    .faq-blocs .row > div:first-child .faq-bloc {
      border-right: 1px #999 solid; }
    .faq-blocs .row > div:last-child {
      padding-left: 5px; }
  .faq-blocs h2 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px; }
  .faq-blocs .faq-bloc > div {
    min-height: 150px; }
  .faq-blocs .faq-bloc .faq-bloc-image {
    float: left;
    width: 25%; }
    .faq-blocs .faq-bloc .faq-bloc-image i {
      text-align: center;
      display: inline-block;
      font-size: 3rem;
      border: 1px #000 solid;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      line-height: 80px;
      height: 80px;
      width: 80px;
      color: #e40115; }
  .faq-blocs .faq-bloc .faq-bloc-info {
    width: 75%;
    padding: 0 15px;
    float: right; }
    .faq-blocs .faq-bloc .faq-bloc-info h3 {
      font-weight: 400;
      font-size: 24px; }
    .faq-blocs .faq-bloc .faq-bloc-info p {
      color: #333; }
  .faq-blocs .faq-bloc:after {
    content: " ";
    display: block;
    height: 1px;
    width: 100%;
    clear: both; }

.navigation {
  background-color: whitesmoke; }

.text-hero-magasin {
  color: #fff;
  font-weight: bold;
  font-size: 16px; }

.see-more-annoces {
  font-weight: bold;
  color: #e40115;
  display: inline-block;
  padding: 10px 20px;
  background-color: #fff;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin: 0 8px; }
  .see-more-annoces img {
    width: 32px !important; }

.modal.fade.in {
  z-index: 9999; }

.ecoommerce-checkout .form-group {
  margin-bottom: 0; }
  .ecoommerce-checkout .form-group select {
    margin-bottom: 0; }
  .ecoommerce-checkout .form-group .help-block {
    margin-bottom: 5px; }

.ecoommerce-checkout address {
  margin-top: 20px;
  color: #333;
  line-height: normal; }

.home-search-bar select, .home-search-bar input, .home-search-bar button {
  display: inline-block;
  background-color: #fff;
  margin: 0; }

.home-search-bar select {
  width: 30%;
  margin-right: -4px;
  border-right: none; }

.home-search-bar input {
  width: 60%;
  border-right: none; }

.home-search-bar button {
  margin-left: -3px;
  float: right;
  width: 10%;
  height: 44px;
  background-color: #e40115;
  border: none;
  color: #fff; }

.hero-search h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px; }
  .hero-search h1 small {
    font-size: 30px;
    font-weight: 400;
    display: block;
    color: #fff; }

.hero-search .home-search-bar {
  width: 500px;
  margin: 0 auto; }
  .hero-search .home-search-bar input {
    width: 456px;
    border-right: none; }
  .hero-search .home-search-bar button {
    width: 44px; }

#owl-hero .container {
  height: 400px; }
  #owl-hero .container .assuredby {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 400px;
    text-align: right;
    padding-right: 20px;
    padding-bottom: 20px; }
    #owl-hero .container .assuredby img {
      float: right;
      margin-left: 5px;
      width: 80px; }
    #owl-hero .container .assuredby p {
      display: inline;
      font-size: 12px;
      color: #fff; }

.header-intro-text p {
  margin-bottom: 0; }

.el-panel {
  padding: 60px 0;
  margin-top: 0;
  margin-bottom: 0; }
  .el-panel.el-panel-first {
    padding-top: 60px; }
  .el-panel.el-panel-last {
    padding-bottom: 60px; }

.home-blocs .home-bloc {
  -webkit-box-shadow: 1px 0 5px #000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: 20px;
  min-height: 340px;
  flex: 1; }
  .home-blocs .home-bloc .home-bloc-image {
    width: 80px;
    margin: 0 auto; }
  .home-blocs .home-bloc h2 {
    font-size: 16px;
    font-weight: 400;
    margin: 30px 0 20px 0; }
  .home-blocs .home-bloc p {
    text-align: center;
    font-size: 14px; }

.store-btns {
  padding-top: 40px; }
  .store-btns a {
    margin: 0 5px; }

.catalogue .left-sidebar .navbar-header {
  height: auto; }

.catalogue .left-sidebar .widget.categories .ais-Menu__root .ais-Menu__item:last-child {
  margin-bottom: 15px; }

.catalogue .left-sidebar .widget.categories .ais-Menu__root .ais-Menu__item .ais-Menu__itemLink {
  display: block;
  width: 100%;
  position: relative;
  padding: 9px 0; }
  .catalogue .left-sidebar .widget.categories .ais-Menu__root .ais-Menu__item .ais-Menu__itemLink .ais-Menu__itemLabel {
    display: inline-block;
    width: 90%;
    line-height: 22px;
    color: #7f7f7f; }
  .catalogue .left-sidebar .widget.categories .ais-Menu__root .ais-Menu__item .ais-Menu__itemLink .ais-Menu__itemCount {
    background-color: whitesmoke;
    color: #7f7f7f;
    position: absolute;
    height: 28px;
    line-height: 28px;
    top: 6px;
    right: 0; }

.catalogue .left-sidebar .widget .ais-ClearAll__root {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border: none;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  background-color: #000;
  padding: 0 34px;
  height: 44px;
  font-size: 12px;
  -webkit-border-radius: 0;
  border-radius: 0;
  width: 100%; }

.pagination-wrap {
  text-align: right; }
  .pagination-wrap .ais-Pagination__root {
    box-shadow: none;
    border: none;
    padding: 0; }
    .pagination-wrap .ais-Pagination__root .ais-Pagination__item {
      padding: 0;
      min-width: 44px;
      max-height: 44px;
      margin: 0 2px; }
      .pagination-wrap .ais-Pagination__root .ais-Pagination__item a, .pagination-wrap .ais-Pagination__root .ais-Pagination__item span {
        display: block;
        color: #000;
        border-radius: 0;
        border: 2px #ccc solid;
        height: 40px;
        min-width: 40px;
        line-height: 40px;
        background-color: #fff;
        font-size: 16px; }
      .pagination-wrap .ais-Pagination__root .ais-Pagination__item.ais-Pagination__itemSelected {
        background-color: #fff; }
        .pagination-wrap .ais-Pagination__root .ais-Pagination__item.ais-Pagination__itemSelected a {
          border-color: #000; }

.ais-SearchBox__wrapper .ais-SearchBox__input {
  padding-right: 50px;
  background-color: #fff;
  border-color: #fff;
  line-height: normal; }

.ais-SearchBox__wrapper .ais-SearchBox__submit, .ais-SearchBox__wrapper .ais-SearchBox__reset {
  margin-left: 0;
  float: none;
  border-radius: 0;
  width: 44px;
  height: 44px;
  right: 0;
  left: inherit;
  background-color: #e40115;
  border: none; }
  .ais-SearchBox__wrapper .ais-SearchBox__submit svg, .ais-SearchBox__wrapper .ais-SearchBox__reset svg {
    fill: #fff; }

.ais-SearchBox__wrapper .ais-SearchBox__reset {
  background-color: black;
  top: 0; }
  .ais-SearchBox__wrapper .ais-SearchBox__reset svg {
    display: inline-block; }

.price .amount {
  font-size: 12px; }

.navigation.sticky.scrolling .nav-menu .nav-menu-text {
  padding: 8px 0; }

/*.ais-SearchBox__root, .intro-text {
    position: absolute;
    max-width: 500px;
	left: 50px;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.intro-text {
	text-align:center;
	p {
		margin: 0
	}
}

.intro-text {
	text-align:left;
	position: relative;
	margin: 0;
	p {
		margin: 0
	}
}*/
.navbar-toggle {
  display: none !important; }

.nav-menu > div {
  float: left;
  text-align: left; }

.nav-menu .nav-menu-logo {
  max-width: 132px;
  min-width: 132px; }

.nav-menu .nav-menu-text {
  width: 55%;
  padding: 26px 0; }
  .nav-menu .nav-menu-text form {
    max-width: 100%; }
  .nav-menu .nav-menu-text p {
    margin: 0; }

.nav-menu .nav-menu-links {
  text-align: right;
  float: right;
  width: 25%; }
  .nav-menu .nav-menu-links > ul {
    text-align: right; }
    .nav-menu .nav-menu-links > ul > li {
      padding: 0 8px; }
      .nav-menu .nav-menu-links > ul > li > a {
        font-size: 13px; }

.product-title {
  min-height: 40px; }

.breadcrumb > li + li:before {
  content: "❯"; }

.payment_methods li .show {
  margin-left: 28px; }

.coupon .form-group {
  margin-bottom: 0px; }

.product-item .price {
  float: left; }

#trendy-items .hover-overlay {
  background-color: #00A583; }

@media (max-width: 767px) {
  .my-account .section-wrap {
    padding: 80px 0 !important; } }

.product-img .choose-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30px;
  min-width: 0%;
  border-radius: 50%; }

.product-slider .choose-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30px;
  min-width: 0%;
  border-radius: 50%; }

.product-gallery {
  position: relative; }
  .product-gallery .choose-icon {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 40px;
    min-width: 0%;
    border-radius: 50%; }

.choose-icon {
  width: 20px;
  border-radius: 50%; }

.dashboard > .row {
  margin: 0; }
  .dashboard > .row > div {
    padding: 0; }
    .dashboard > .row > div > ul li {
      border-bottom: 1px solid whitesmoke; }
      .dashboard > .row > div > ul li a {
        display: block;
        width: 100%;
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        font-weight: 600;
        font-size: 14px;
        color: #000; }
        .dashboard > .row > div > ul li a:hover, .dashboard > .row > div > ul li a:focus {
          color: #e40115; }
      .dashboard > .row > div > ul li.active {
        background: whitesmoke; }
        .dashboard > .row > div > ul li.active a {
          color: #e40115; }
    .dashboard > .row > div .dash-content {
      padding: 30px;
      border-left: 1px solid whitesmoke; }
      .dashboard > .row > div .dash-content > .row {
        margin: 0; }

@media (max-width: 767px) {
  .dashboard > .row > div > ul {
    text-align: center; }
    .dashboard > .row > div > ul li {
      border-bottom: none;
      border-right: 1px solid whitesmoke;
      display: inline-block; }
      .dashboard > .row > div > ul li a {
        padding: 20px;
        line-height: normal;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        height: auto; }
        .dashboard > .row > div > ul li a i {
          font-size: 2.5rem;
          display: block;
          margin-bottom: 10px; }
      .dashboard > .row > div > ul li:last-child {
        border-right: none; }
    .dashboard > .row > div > ul:after {
      content: " ";
      display: block;
      clear: both;
      width: 100%;
      height: 10px; } }

@media (max-width: 615px) {
  .dashboard > .row > div > ul li a {
    padding: 10px;
    font-size: 11px; }
    .dashboard > .row > div > ul li a i {
      font-size: 2rem; } }

@media (max-width: 500px) {
  .dashboard > .row > div > ul li a {
    padding: 20px; }
    .dashboard > .row > div > ul li a span {
      display: none; }
    .dashboard > .row > div > ul li a i {
      margin: 0;
      font-size: 1.5rem; } }

.be-loading-active:after {
  visibility: visible !important;
  opacity: 1 !important; }

.be-loading:after {
  position: absolute;
  display: block;
  visibility: hidden;
  opacity: 0;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  transition: all .2s ease;
  z-index: 2; }

/*-------------------------------------------------------*/
/* Responsive Styles
/*-------------------------------------------------------*/
@media (max-width: 1199px) {
  #products-grid .product-item {
    width: 33.33333%; } }

@media (max-width: 991px) {
  h1 {
    font-size: 28px; }
  h2 {
    font-size: 25px; }
  h3 {
    font-size: 22px; }
  h4 {
    font-size: 19px; }
  .section-wrap {
    padding: 80px 0;
    background-attachment: scroll !important;
    background-position: 50% 50% !important; }
  .container-fluid.semi-fluid {
    padding: 0 30px; }
  .call-to-action h2 {
    font-size: 36px; }
  .footer-widgets .row > div {
    margin-bottom: 60px; }
  .title-text h1 {
    font-size: 24px; }
  .blog-standard .post-content {
    padding-right: 15px; }
  .project-description {
    padding-left: 15px;
    margin-top: 30px; }
  .heading-row {
    margin-bottom: 40px; }
  .testimonials .testimonial-text {
    font-size: 22px;
    line-height: 36px; }
  #products-grid .product-item {
    width: 50%; } }

@media (max-width: 767px) {
  .container-fluid.semi-fluid {
    padding: 0 15px; }
  .call-to-action h3 {
    margin-bottom: 20px; }
  .entry-content {
    padding-left: 0;
    border-left: none;
    margin-top: 30px; }
  .entry-wrap {
    padding-top: 30px; }
  .ecommerce-ordering {
    width: 60%;
    float: none;
    margin: auto; } }

@media (max-width: 640px) {
  .col-xxs-12 {
    width: 100%; }
  .col-xxs-6 {
    width: 50%; }
  .entry .blockquote-style-1 {
    padding: 40px; }
  .entry .blockquote-style-1 p:before {
    display: none; }
  .pagination a:first-child {
    margin-right: 5px;
    width: 20px; }
  .pagination a:last-child {
    margin-left: 5px;
    width: 20px; }
  .pagination a > i {
    font-size: 20px; } }

@media (max-width: 540px) {
  .table-wrap {
    overflow-x: scroll; } }

@media (max-width: 480px) {
  .page-404 h1 {
    font-size: 100px; }
  #products-grid .product-item,
  .product {
    width: 100%; }
  .newsletter .newsletter-form {
    width: 100%;
    margin-right: 0; }
  .vertical .nav-tabs,
  .nav-tabs > li {
    float: none;
    width: 100%; }
  .vertical .nav-tabs li {
    padding-bottom: 0; }
  .vertical .nav.nav-tabs > li.active > a {
    right: 0;
    padding-left: 20px; }
  .vertical .tab-content {
    border-top: 0; } }

@media (max-width: 1250px) {
  .parlent-de-nous {
    padding-bottom: 0; }
    .parlent-de-nous img {
      margin-bottom: 30px; } }

@media (max-width: 1200px) {
  .how-to-blocs {
    width: 100%; }
    .how-to-blocs .row > p {
      width: 100%;
      margin-bottom: 15px; }
      .how-to-blocs .row > p i {
        float: none;
        font-size: 24px;
        margin-right: 10px; }
      .how-to-blocs .row > p span.line-return {
        display: inline; }
      .how-to-blocs .row > p span.middle-align {
        display: inline;
        height: auto;
        line-height: auto; }
  .ais-horizontal-menu .ais-Menu__root {
    width: 25%;
    float: left; }
  .ais-horizontal-menu .ais-Menu__items {
    text-align: left;
    border-right: 1px #e5e5e5 solid;
    border-bottom: none;
    border-top: none; }
    .ais-horizontal-menu .ais-Menu__items .ais-Menu__item {
      display: block;
      border-bottom: 1px #e5e5e5 solid;
      padding: 0; }
      .ais-horizontal-menu .ais-Menu__items .ais-Menu__item a {
        height: auto;
        line-height: auto;
        padding: 20px; }
  .how-to-blocs {
    padding: 30px 15px; }
    .how-to-blocs .row > div {
      margin-bottom: 5px; }
  .single-product {
    padding-left: 40px; }
  .product-slider, .product-description-wrap {
    width: 100%; } }

@media (min-width: 992px) and (max-width: 1650px) {
  .callCenter .innerCallCenter .logoCdiscount {
    width: 35%; }
  .callCenter .innerCallCenter .openingDaya {
    width: 65%;
    text-align: left; }
  .callCenter .innerCallCenter .detailaCallCenter {
    display: block;
    width: 100%;
    text-align: right; } }

@media (max-width: 1533px) {
  .call-center .call-center--container .company-logo {
    width: 40%; }
  .call-center .call-center--container .company-phone-number {
    width: 60%; }
    .call-center .call-center--container .company-phone-number p {
      font-size: 28px; }
  .call-center .call-center--container .company-times {
    width: 100%; }
    .call-center .call-center--container .company-times p span {
      display: inline-block;
      margin-left: 10px; } }

@media (min-width: 1200px) and (max-width: 1267px) {
  .call-center .call-center--container .company-logo {
    width: 30%; }
  .call-center .call-center--container .company-phone-number {
    width: 70%; }
    .call-center .call-center--container .company-phone-number p {
      font-size: 24px; }
  .call-center .call-center--container .company-times {
    width: 100%; }
    .call-center .call-center--container .company-times p {
      text-align: center; }
      .call-center .call-center--container .company-times p span {
        display: block;
        margin-left: 0px; } }

@media (min-width: 480px) {
  .hack .product-img, .hack .product-item {
    background-color: #fff;
    width: 210.5px; } }

@media (min-width: 767px) {
  .hack .product-img, .hack .product-item {
    width: 212.5px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .left-sidebar.col-md-3 {
    width: 33% !important; }
  .left-sidebar .navbar-collapse.collapse {
    display: block !important; } }

@media (max-width: 1130px) {
  .product-actions > form .form-group .datepickup .SingleDatePicker .SingleDatePickerInput .DateInput > div {
    font-size: 16px; }
  .product-actions > form .form-group .hourpickup select {
    font-size: 16px; } }

@media (max-width: 1050px) {
  .product-actions > form .form-group .datepickup {
    width: 55%; }
  .product-actions > form .form-group .hourpickup {
    width: 45%; } }

@media (max-width: 992px) and (min-width: 767px) {
  .catalogue .container .row > .catalogue-col {
    width: 66.66666%; }
  .product-img, .product-item {
    width: auto; }
  .ais-Hits__root {
    width: 100%; }
  .items-grid > div > div {
    width: 50%; }
    .items-grid > div > div > img {
      width: 100%; } }

@media (max-width: 992px) {
  .home-top-categories {
    text-align: center; }
  .nav-cart-express {
    top: 22px; }
  .ais-SearchBox__root {
    top: 8px; }
  .nav-menu .nav-menu-text {
    width: 50%;
    padding: 2px 0; }
    .nav-menu .nav-menu-text p {
      font-size: 12px;
      line-height: 18px; }
  .nav-menu .nav-menu-links {
    width: 30%; } }

@media (max-width: 960px) {
  .faq-blocs {
    padding: 30px 0 10px 0; }
    .faq-blocs .row {
      padding: 0; }
      .faq-blocs .row > div:first-child .faq-bloc {
        border-right: none; }
      .faq-blocs .row > div:last-child {
        padding-left: 15px; }
    .faq-blocs .faq-bloc {
      margin-bottom: 20px; }
      .faq-blocs .faq-bloc > div {
        min-height: initial; }
      .faq-blocs .faq-bloc .faq-bloc-image {
        text-align: center;
        width: 15%; }
      .faq-blocs .faq-bloc .faq-bloc-info {
        width: 85%; }
        .faq-blocs .faq-bloc .faq-bloc-info h3 {
          font-size: 18px; } }

@media (min-width: 767px) and (max-width: 850px) {
  .call-center .call-center--container .company-logo {
    width: 30%; }
  .call-center .call-center--container .company-phone-number {
    width: 70%; }
    .call-center .call-center--container .company-phone-number p {
      font-size: 24px; }
  .call-center .call-center--container .company-times {
    width: 100%; }
    .call-center .call-center--container .company-times p {
      text-align: center; }
      .call-center .call-center--container .company-times p span {
        display: block;
        margin-left: 0px; } }

@media (max-width: 800px) {
  footer {
    text-align: center; }
    footer .row > div {
      margin-bottom: 30px; }
      footer .row > div:last-child {
        margin-bottom: 0; } }

@media (max-width: 767px) {
  .product-actions > form .form-group > div .dateContainer {
    padding: 0 !important; }
  .section-wrap, .pt-80 {
    padding: 0 !important; }
  .single-product-wrapper .single-product {
    width: 100%; }
  .single-product-wrapper .ais-InstantSearch__root {
    width: 100%; }
    .single-product-wrapper .ais-InstantSearch__root .ais-horizontal-menu {
      display: none; }
      .single-product-wrapper .ais-InstantSearch__root .ais-horizontal-menu .ais-Menu__root {
        margin-bottom: 50px;
        width: 100%;
        float: auto; }
      .single-product-wrapper .ais-InstantSearch__root .ais-horizontal-menu .ais-Menu__items {
        text-align: center;
        border-top: 1px #e5e5e5 solid;
        border-left: 1px #e5e5e5 solid; }
        .single-product-wrapper .ais-InstantSearch__root .ais-horizontal-menu .ais-Menu__items .ais-Menu__item {
          border-bottom: 1px #e5e5e5 solid;
          border-right: 1px #e5e5e5 solid;
          padding: 15px 0; }
          .single-product-wrapper .ais-InstantSearch__root .ais-horizontal-menu .ais-Menu__items .ais-Menu__item a {
            height: 25px;
            line-height: 25px;
            padding: 0 10px; }
  .home-blocs .container {
    width: 380px; }
    .home-blocs .container .home-bloc {
      min-height: auto;
      margin-bottom: 15px; }
  .nav-menu .nav-menu-text {
    width: 40%; }
  .nav-menu .nav-menu-links {
    width: 40%; }
  .nav-cart-express {
    right: 15px; }
  .ais-SearchBox__root {
    max-width: 300px; }
  .left-sidebar {
    width: 100% !important; }
    .left-sidebar .navbar-toggle {
      display: block !important; }
    .left-sidebar .navbar-toggle-label {
      float: right;
      margin-left: 30px;
      margin-top: -4px; }
    .left-sidebar .ais-Menu__root {
      max-width: 100%; }
      .left-sidebar .ais-Menu__root .ais-Menu__item {
        float: left;
        width: 50%; }
        .left-sidebar .ais-Menu__root .ais-Menu__item .ais-Menu__itemCount {
          right: 15px !important; } }

@media (max-width: 700px) {
  .faq-blocs .faq-bloc .faq-bloc-image {
    text-align: center;
    width: 20%; }
  .faq-blocs .faq-bloc .faq-bloc-info {
    width: 80%; } }

@media (max-width: 600px) {
  .actions.action-right {
    float: right; }
  .navbar {
    min-height: 104px; }
  .SingleDatePicker_picker__directionLeft {
    z-index: 102; }
  .ais-SearchBox__wrapper .ais-SearchBox__input {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
  .nav-menu .nav-menu-text {
    position: absolute;
    top: 52px;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0; }
    .nav-menu .nav-menu-text.nav-menu-text-show {
      display: block;
      float: none;
      width: 100%; }
    .nav-menu .nav-menu-text form {
      margin-bottom: 10px; }
  .nav-menu .nav-menu-links {
    width: auto; }
  .call-center .call-center--container .company-logo {
    width: 30%; }
  .call-center .call-center--container .company-phone-number {
    width: 70%; }
    .call-center .call-center--container .company-phone-number p {
      font-size: 24px; }
  .call-center .call-center--container .company-times {
    width: 100%; }
    .call-center .call-center--container .company-times p {
      text-align: center; }
      .call-center .call-center--container .company-times p span {
        display: block;
        margin-left: 0px; } }

@media (max-width: 560px) {
  .ais-SearchBox__wrapper .ais-SearchBox__input {
    height: 44px;
    padding: 0 10px;
    line-height: 44px; }
  .faq-blocs .faq-bloc .faq-bloc-image {
    text-align: center;
    width: 15%; }
    .faq-blocs .faq-bloc .faq-bloc-image i {
      width: 45px;
      height: 45px;
      line-height: 45px; }
  .faq-blocs .faq-bloc .faq-bloc-info {
    width: 85%; }
  .intro-text {
    display: none; }
  .product-actions > span {
    width: 40px; }
  .product-actions > a {
    padding: 0 10px; }
  .hero-search h1 {
    font-size: 2em; }
  .hero-search .home-search-bar {
    width: 90%;
    margin-left: 5%; }
    .hero-search .home-search-bar input {
      width: 85%;
      font-size: 16px; }
    .hero-search .home-search-bar button {
      width: 15%; }
  input.btn.btn-lg, button.btn.btn-lg {
    max-width: 100%;
    width: 100%;
    padding: 0;
    text-align: center; } }

@media (max-width: 560px) {
  .hero-holder .see-more-annoces {
    width: 250px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-top: 10px; } }

@media (max-width: 480px) {
  .cart-actions > div {
    width: 100%;
    text-align: center; }
    .cart-actions > div > div {
      float: none !important;
      text-align: center; }
    .cart-actions > div a {
      width: 200px;
      text-align: center;
      padding: 0;
      margin: 5px 0; } }

@media (max-width: 460px) {
  .call-center .call-center--container .company-logo {
    text-align: center;
    width: 100%; }
  .call-center .call-center--container .company-phone-number {
    width: 100%; }
    .call-center .call-center--container .company-phone-number p {
      font-size: 28px; } }

@media (max-width: 440px) {
  .store-btns a {
    display: block;
    margin: 0 0 5px 0; }
  .home-blocs .container {
    width: 100%; }
  .left-sidebar .ais-Menu__root .ais-Menu__item {
    width: 100%;
    float: none; }
    .left-sidebar .ais-Menu__root .ais-Menu__item .ais-Menu__itemCount {
      right: 0 !important; }
  .modal-footer > a {
    margin-right: 0;
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
    display: block; }
  .modal-footer > button {
    display: block;
    margin: 0 auto; }
  #offerModal .modal-dialog {
    width: 380px; }
    #offerModal .modal-dialog .modal-content {
      background-position: bottom center;
      width: 380px;
      height: 380px;
      padding-top: 20px; }
      #offerModal .modal-dialog .modal-content .offer-coupon {
        height: 70px;
        line-height: 67px;
        font-size: 3.5rem; } }

@media (max-width: 420px) {
  .faq-blocs .faq-bloc .faq-bloc-image {
    display: none; }
  .faq-blocs .faq-bloc .faq-bloc-info {
    width: 100%; } }

@media (max-width: 380px) {
  .nav-menu .nav-menu-logo {
    padding: 0; }
  .nav-menu .nav-menu-links > ul > li > a {
    font-size: 11px; }
  .call-center .call-center--container {
    padding: 8px; }
    .call-center .call-center--container:before {
      margin: 0 auto;
      position: relative;
      left: auto;
      top: auto; }
    .call-center .call-center--container .company-phone-number p {
      font-size: 20px; } }

@media (max-width: 290px) {
  .call-center .call-center--container .company-phone-number p {
    line-height: 64px;
    text-align: center; }
    .call-center .call-center--container .company-phone-number p:before {
      display: none; } }

.login-form button {
  width: 100%; }

/*-------------------------------------------------------*/
/* Spacings
/*-------------------------------------------------------*/
.nomargin {
  margin: 0 !important; }

.nopadding {
  padding: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-10 {
  margin-top: 10px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-40 {
  margin-top: 40px; }

.mt-50 {
  margin-top: 50px; }

.mt-60 {
  margin-top: 60px; }

.mt-70 {
  margin-top: 70px; }

.mt-80 {
  margin-top: 80px; }

.mt-90 {
  margin-top: 90px; }

.mt-100 {
  margin-top: 100px; }

.mt-110 {
  margin-top: 110px; }

.mt-120 {
  margin-top: 120px; }

.mt-130 {
  margin-top: 130px; }

.mt-140 {
  margin-top: 140px; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-10 {
  margin-bottom: 10px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-80 {
  margin-bottom: 80px; }

.mb-90 {
  margin-bottom: 90px; }

.mb-100 {
  margin-bottom: 100px; }

.mb-110 {
  margin-bottom: 110px; }

.mb-120 {
  margin-bottom: 120px; }

.mb-130 {
  margin-bottom: 130px; }

.mb-140 {
  margin-bottom: 140px; }

.pt-0 {
  padding-top: 0; }

.pt-10 {
  padding-top: 10px; }

.pt-20 {
  padding-top: 20px; }

.pt-30 {
  padding-top: 30px; }

.pt-40 {
  padding-top: 40px; }

.pt-50 {
  padding-top: 50px; }

.pt-60 {
  padding-top: 60px; }

.pt-70 {
  padding-top: 70px; }

.pt-80 {
  padding-top: 80px; }

.pt-90 {
  padding-top: 90px; }

.pt-100 {
  padding-top: 100px; }

.pt-110 {
  padding-top: 110px; }

.pt-120 {
  padding-top: 120px; }

.pt-130 {
  padding-top: 130px; }

.pt-140 {
  padding-top: 140px; }

.pt-150 {
  padding-top: 150px; }

.pt-160 {
  padding-top: 160px; }

.pt-170 {
  padding-top: 170px; }

.pt-180 {
  padding-top: 180px; }

.pt-190 {
  padding-top: 190px; }

.pt-200 {
  padding-top: 200px; }

.pb-0 {
  padding-bottom: 0; }

.pb-10 {
  padding-bottom: 10px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-40 {
  padding-bottom: 40px; }

.pb-50 {
  padding-bottom: 50px; }

.pb-60 {
  padding-bottom: 60px; }

.pb-70 {
  padding-bottom: 70px; }

.pb-80 {
  padding-bottom: 80px; }

.pb-90 {
  padding-bottom: 90px; }

.pb-100 {
  padding-bottom: 100px; }

.pb-110 {
  padding-bottom: 110px; }

.pb-120 {
  padding-bottom: 120px; }

.pb-130 {
  padding-bottom: 130px; }

.pb-140 {
  padding-bottom: 140px; }

.pb-150 {
  padding-bottom: 150px; }

.pb-160 {
  padding-bottom: 160px; }

.pb-170 {
  padding-bottom: 170px; }

.pb-180 {
  padding-bottom: 180px; }

.pb-190 {
  padding-bottom: 190px; }

.pb-200 {
  padding-bottom: 200px; }

@media only screen and (max-width: 1199px) {
  .mt-lrg-0 {
    margin-top: 0 !important; }
  .mt-lrg-10 {
    margin-top: 10px; }
  .mt-lrg-20 {
    margin-top: 20px; }
  .mt-lrg-30 {
    margin-top: 30px; }
  .mt-lrg-40 {
    margin-top: 40px; }
  .mt-lrg-50 {
    margin-top: 50px; }
  .mt-lrg-60 {
    margin-top: 60px; }
  .mt-lrg-70 {
    margin-top: 70px; }
  .mt-lrg-80 {
    margin-top: 80px; }
  .mt-lrg-90 {
    margin-top: 90px; }
  .mt-lrg-100 {
    margin-top: 100px; }
  .mt-lrg-110 {
    margin-top: 110px; }
  .mt-lrg-120 {
    margin-top: 120px; }
  .mt-lrg-130 {
    margin-top: 130px; }
  .mt-lrg-140 {
    margin-top: 140px; }
  .mb-lrg-0 {
    margin-bottom: 0 !important; }
  .mb-lrg-10 {
    margin-bottom: 10px; }
  .mb-lrg-20 {
    margin-bottom: 20px; }
  .mb-lrg-30 {
    margin-bottom: 30px; }
  .mb-lrg-40 {
    margin-bottom: 40px; }
  .mb-lrg-50 {
    margin-bottom: 50px; }
  .mb-lrg-60 {
    margin-bottom: 60px; }
  .mb-lrg-70 {
    margin-bottom: 70px; }
  .mb-lrg-80 {
    margin-bottom: 80px; }
  .mb-lrg-90 {
    margin-bottom: 90px; }
  .mb-lrg-100 {
    margin-bottom: 100px; }
  .mb-lrg-110 {
    margin-bottom: 110px; }
  .mb-lrg-120 {
    margin-bottom: 120px; }
  .mb-lrg-130 {
    margin-bottom: 130px; }
  .mb-lrg-140 {
    margin-bottom: 140px; }
  .pt-lrg-0 {
    padding-top: 0; }
  .pt-lrg-10 {
    padding-top: 10px; }
  .pt-lrg-20 {
    padding-top: 20px; }
  .pt-lrg-30 {
    padding-top: 30px; }
  .pt-lrg-40 {
    padding-top: 40px; }
  .pt-lrg-50 {
    padding-top: 50px; }
  .pt-lrg-60 {
    padding-top: 60px; }
  .pt-lrg-70 {
    padding-top: 70px; }
  .pt-lrg-80 {
    padding-top: 80px; }
  .pt-lrg-90 {
    padding-top: 90px; }
  .pt-lrg-100 {
    padding-top: 100px; }
  .pt-lrg-110 {
    padding-top: 110px; }
  .pt-lrg-120 {
    padding-top: 120px; }
  .pt-lrg-130 {
    padding-top: 130px; }
  .pt-lrg-140 {
    padding-top: 140px; }
  .pb-lrg-0 {
    padding-bottom: 0; }
  .pb-lrg-10 {
    padding-bottom: 10px; }
  .pb-lrg-20 {
    padding-bottom: 20px; }
  .pb-lrg-30 {
    padding-bottom: 30px; }
  .pb-lrg-40 {
    padding-bottom: 40px; }
  .pb-lrg-50 {
    padding-bottom: 50px; }
  .pb-lrg-60 {
    padding-bottom: 60px; }
  .pb-lrg-70 {
    padding-bottom: 70px; }
  .pb-lrg-80 {
    padding-bottom: 80px; }
  .pb-lrg-90 {
    padding-bottom: 90px; }
  .pb-lrg-100 {
    padding-bottom: 100px; }
  .pb-lrg-110 {
    padding-bottom: 110px; }
  .pb-lrg-120 {
    padding-bottom: 120px; }
  .pb-lrg-130 {
    padding-bottom: 130px; }
  .pb-lrg-140 {
    padding-bottom: 140px; } }

@media only screen and (max-width: 991px) {
  .mt-mdm-0 {
    margin-top: 0 !important; }
  .mt-mdm-10 {
    margin-top: 10px; }
  .mt-mdm-20 {
    margin-top: 20px; }
  .mt-mdm-30 {
    margin-top: 30px; }
  .mt-mdm-40 {
    margin-top: 40px; }
  .mt-mdm-50 {
    margin-top: 50px; }
  .mt-mdm-60 {
    margin-top: 60px; }
  .mt-mdm-70 {
    margin-top: 70px; }
  .mt-mdm-80 {
    margin-top: 80px; }
  .mt-mdm-90 {
    margin-top: 90px; }
  .mt-mdm-100 {
    margin-top: 100px; }
  .mt-mdm-110 {
    margin-top: 110px; }
  .mt-mdm-120 {
    margin-top: 120px; }
  .mt-mdm-130 {
    margin-top: 130px; }
  .mt-mdm-140 {
    margin-top: 140px; }
  .mb-mdm-0 {
    margin-bottom: 0 !important; }
  .mb-mdm-10 {
    margin-bottom: 10px; }
  .mb-mdm-20 {
    margin-bottom: 20px; }
  .mb-mdm-30 {
    margin-bottom: 30px; }
  .mb-mdm-40 {
    margin-bottom: 40px; }
  .mb-mdm-50 {
    margin-bottom: 50px; }
  .mb-mdm-60 {
    margin-bottom: 60px; }
  .mb-mdm-70 {
    margin-bottom: 70px; }
  .mb-mdm-80 {
    margin-bottom: 80px; }
  .mb-mdm-90 {
    margin-bottom: 90px; }
  .mb-mdm-100 {
    margin-bottom: 100px; }
  .mb-mdm-110 {
    margin-bottom: 110px; }
  .mb-mdm-120 {
    margin-bottom: 120px; }
  .mb-mdm-130 {
    margin-bottom: 130px; }
  .mb-mdm-140 {
    margin-bottom: 140px; }
  .pt-mdm-0 {
    padding-top: 0; }
  .pt-mdm-10 {
    padding-top: 10px; }
  .pt-mdm-20 {
    padding-top: 20px; }
  .pt-mdm-30 {
    padding-top: 30px; }
  .pt-mdm-40 {
    padding-top: 40px; }
  .pt-mdm-50 {
    padding-top: 50px; }
  .pt-mdm-60 {
    padding-top: 60px; }
  .pt-mdm-70 {
    padding-top: 70px; }
  .pt-mdm-80 {
    padding-top: 80px; }
  .pt-mdm-90 {
    padding-top: 90px; }
  .pt-mdm-100 {
    padding-top: 100px; }
  .pt-mdm-110 {
    padding-top: 110px; }
  .pt-mdm-120 {
    padding-top: 120px; }
  .pt-mdm-130 {
    padding-top: 130px; }
  .pt-mdm-140 {
    padding-top: 140px; }
  .pt-mdm-150 {
    padding-top: 150px; }
  .pb-mdm-0 {
    padding-bottom: 0; }
  .pb-mdm-10 {
    padding-bottom: 10px; }
  .pb-mdm-20 {
    padding-bottom: 20px; }
  .pb-mdm-30 {
    padding-bottom: 30px; }
  .pb-mdm-40 {
    padding-bottom: 40px; }
  .pb-mdm-50 {
    padding-bottom: 50px; }
  .pb-mdm-60 {
    padding-bottom: 60px; }
  .pb-mdm-70 {
    padding-bottom: 70px; }
  .pb-mdm-80 {
    padding-bottom: 80px; }
  .pb-mdm-90 {
    padding-bottom: 90px; }
  .pb-mdm-100 {
    padding-bottom: 100px; }
  .pb-mdm-110 {
    padding-bottom: 110px; }
  .pb-mdm-120 {
    padding-bottom: 120px; }
  .pb-mdm-130 {
    padding-bottom: 130px; }
  .pb-mdm-140 {
    padding-bottom: 140px; }
  .pb-mdm-150 {
    padding-bottom: 150px; } }

@media only screen and (max-width: 767px) {
  .mt-sml-0 {
    margin-top: 0 !important; }
  .mt-sml-10 {
    margin-top: 10px; }
  .mt-sml-20 {
    margin-top: 20px; }
  .mt-sml-30 {
    margin-top: 30px; }
  .mt-sml-40 {
    margin-top: 40px; }
  .mt-sml-50 {
    margin-top: 50px; }
  .mt-sml-60 {
    margin-top: 60px; }
  .mt-sml-70 {
    margin-top: 70px; }
  .mt-sml-80 {
    margin-top: 80px; }
  .mt-sml-90 {
    margin-top: 90px; }
  .mt-sml-100 {
    margin-top: 100px; }
  .mt-sml-110 {
    margin-top: 110px; }
  .mt-sml-120 {
    margin-top: 120px; }
  .mt-sml-130 {
    margin-top: 130px; }
  .mt-sml-140 {
    margin-top: 140px; }
  .mb-sml-0 {
    margin-bottom: 0 !important; }
  .mb-sml-10 {
    margin-bottom: 10px; }
  .mb-sml-20 {
    margin-bottom: 20px; }
  .mb-sml-30 {
    margin-bottom: 30px; }
  .mb-sml-40 {
    margin-bottom: 40px; }
  .mb-sml-50 {
    margin-bottom: 50px; }
  .mb-sml-60 {
    margin-bottom: 60px; }
  .mb-sml-70 {
    margin-bottom: 70px; }
  .mb-sml-80 {
    margin-bottom: 80px; }
  .mb-sml-90 {
    margin-bottom: 90px; }
  .mb-sml-100 {
    margin-bottom: 100px; }
  .mb-sml-110 {
    margin-bottom: 110px; }
  .mb-sml-120 {
    margin-bottom: 120px; }
  .mb-sml-130 {
    margin-bottom: 130px; }
  .mb-sml-140 {
    margin-bottom: 140px; }
  .pt-sml-0 {
    padding-top: 0; }
  .pt-sml-10 {
    padding-top: 10px; }
  .pt-sml-20 {
    padding-top: 20px; }
  .pt-sml-30 {
    padding-top: 30px; }
  .pt-sml-40 {
    padding-top: 40px; }
  .pt-sml-50 {
    padding-top: 50px; }
  .pt-sml-60 {
    padding-top: 60px; }
  .pt-sml-70 {
    padding-top: 70px; }
  .pt-sml-80 {
    padding-top: 80px; }
  .pt-sml-90 {
    padding-top: 90px; }
  .pt-sml-100 {
    padding-top: 100px; }
  .pt-sml-110 {
    padding-top: 110px; }
  .pt-sml-120 {
    padding-top: 120px; }
  .pt-sml-130 {
    padding-top: 130px; }
  .pt-sml-140 {
    padding-top: 140px; }
  .pb-sml-0 {
    padding-bottom: 0; }
  .pb-sml-10 {
    padding-bottom: 10px; }
  .pb-sml-20 {
    padding-bottom: 20px; }
  .pb-sml-30 {
    padding-bottom: 30px; }
  .pb-sml-40 {
    padding-bottom: 40px; }
  .pb-sml-50 {
    padding-bottom: 50px; }
  .pb-sml-60 {
    padding-bottom: 60px; }
  .pb-sml-70 {
    padding-bottom: 70px; }
  .pb-sml-80 {
    padding-bottom: 80px; }
  .pb-sml-90 {
    padding-bottom: 90px; }
  .pb-sml-100 {
    padding-bottom: 100px; }
  .pb-sml-110 {
    padding-bottom: 110px; }
  .pb-sml-120 {
    padding-bottom: 120px; }
  .pb-sml-130 {
    padding-bottom: 130px; }
  .pb-sml-140 {
    padding-bottom: 140px; } }

@media only screen and (max-width: 480px) {
  .mt-xsm-0 {
    margin-top: 0 !important; }
  .mt-xsm-10 {
    margin-top: 10px; }
  .mt-xsm-20 {
    margin-top: 20px; }
  .mt-xsm-30 {
    margin-top: 30px; }
  .mt-xsm-40 {
    margin-top: 40px; }
  .mt-xsm-50 {
    margin-top: 50px; }
  .mt-xsm-60 {
    margin-top: 60px; }
  .mt-xsm-70 {
    margin-top: 70px; }
  .mt-xsm-80 {
    margin-top: 80px; }
  .mt-xsm-90 {
    margin-top: 90px; }
  .mt-xsm-100 {
    margin-top: 100px; }
  .mt-xsm-110 {
    margin-top: 110px; }
  .mt-xsm-120 {
    margin-top: 120px; }
  .mt-xsm-130 {
    margin-top: 130px; }
  .mt-xsm-140 {
    margin-top: 140px; }
  .mb-xsm-0 {
    margin-bottom: 0 !important; }
  .mb-xsm-10 {
    margin-bottom: 10px; }
  .mb-xsm-20 {
    margin-bottom: 20px; }
  .mb-xsm-30 {
    margin-bottom: 30px; }
  .mb-xsm-40 {
    margin-bottom: 40px; }
  .mb-xsm-50 {
    margin-bottom: 50px; }
  .mb-xsm-60 {
    margin-bottom: 60px; }
  .mb-xsm-70 {
    margin-bottom: 70px; }
  .mb-xsm-80 {
    margin-bottom: 80px; }
  .mb-xsm-90 {
    margin-bottom: 90px; }
  .mb-xsm-100 {
    margin-bottom: 100px; }
  .mb-xsm-110 {
    margin-bottom: 110px; }
  .mb-xsm-120 {
    margin-bottom: 120px; }
  .mb-xsm-130 {
    margin-bottom: 130px; }
  .mb-xsm-140 {
    margin-bottom: 140px; }
  .pt-xsm-0 {
    padding-top: 0; }
  .pt-xsm-10 {
    padding-top: 10px; }
  .pt-xsm-20 {
    padding-top: 20px; }
  .pt-xsm-30 {
    padding-top: 30px; }
  .pt-xsm-40 {
    padding-top: 40px; }
  .pt-xsm-50 {
    padding-top: 50px; }
  .pt-xsm-60 {
    padding-top: 60px; }
  .pt-xsm-70 {
    padding-top: 70px; }
  .pt-xsm-80 {
    padding-top: 80px; }
  .pt-xsm-90 {
    padding-top: 90px; }
  .pt-xsm-100 {
    padding-top: 100px; }
  .pt-xsm-110 {
    padding-top: 110px; }
  .pt-xsm-120 {
    padding-top: 120px; }
  .pt-xsm-130 {
    padding-top: 130px; }
  .pt-xsm-140 {
    padding-top: 140px; }
  .pb-xsm-0 {
    padding-bottom: 0; }
  .pb-xsm-10 {
    padding-bottom: 10px; }
  .pb-xsm-20 {
    padding-bottom: 20px; }
  .pb-xsm-30 {
    padding-bottom: 30px; }
  .pb-xsm-40 {
    padding-bottom: 40px; }
  .pb-xsm-50 {
    padding-bottom: 50px; }
  .pb-xsm-60 {
    padding-bottom: 60px; }
  .pb-xsm-70 {
    padding-bottom: 70px; }
  .pb-xsm-80 {
    padding-bottom: 80px; }
  .pb-xsm-90 {
    padding-bottom: 90px; }
  .pb-xsm-100 {
    padding-bottom: 100px; }
  .pb-xsm-110 {
    padding-bottom: 110px; }
  .pb-xsm-120 {
    padding-bottom: 120px; }
  .pb-xsm-130 {
    padding-bottom: 130px; }
  .pb-xsm-140 {
    padding-bottom: 140px; } }
