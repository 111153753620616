.ais-InstantSearch__root {
  font-family: sans-serif; }

.ais-ClearAll__root {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  color: #fff;
  background: #3369e7; }

.ais-ClearAll__root:disabled[disabled], .ais-ClearAll__root:disabled:disabled {
  opacity: 0.5;
  pointer-events: none; }

.ais-CurrentRefinements__item {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  color: #fff;
  background: #3369e7; }

.ais-CurrentRefinements__itemClear {
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  color: #fff;
  display: inline-block;
  position: relative; }
  .ais-CurrentRefinements__itemClear:focus, .ais-CurrentRefinements__itemClear:active {
    outline: none; }
  .ais-CurrentRefinements__itemClear:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }

.ais-HierarchicalMenu__items {
  color: #3369e7;
  width: 100%;
  float: left; }

.ais-HierarchicalMenu__item {
  position: relative;
  width: auto;
  float: left;
  clear: both;
  font-size: 14px;
  min-height: 22px;
  line-height: 22px;
  margin-top: 6px; }
  .ais-HierarchicalMenu__item:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: -22px;
    width: 14px;
    height: 14px;
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 8 13' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 1.5l5 4.98-5 5.02' stroke='%23697782' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' opacity='.4'/></svg>") no-repeat center center/contain;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.ais-HierarchicalMenu__itemSelected {
  font-weight: bold; }
  .ais-HierarchicalMenu__itemSelected:after {
    display: none; }
  .ais-HierarchicalMenu__itemSelected .ais-HierarchicalMenu__itemLink {
    position: relative; }
    .ais-HierarchicalMenu__itemSelected .ais-HierarchicalMenu__itemLink:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: -22px;
      width: 14px;
      height: 14px;
      background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 8 13' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 1.5l5 4.98-5 5.02' stroke='%23697782' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' opacity='.4'/></svg>") no-repeat center center/contain;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }

.ais-HierarchicalMenu__itemSelectedParent > .ais-HierarchicalMenu__itemLink:after {
  top: 25%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.ais-HierarchicalMenu__itemLink {
  color: currentColor;
  text-decoration: none; }

.ais-HierarchicalMenu__itemCount {
  border-radius: 31px;
  background-color: rgba(39, 81, 175, 0.1);
  color: #3369e7;
  font-size: 12px;
  padding: 2px 10px; }

.ais-HierarchicalMenu__itemItems {
  padding-left: 14px;
  font-weight: normal; }
  .ais-HierarchicalMenu__itemItems .ais-HierarchicalMenu__item:after,
  .ais-HierarchicalMenu__itemItems .ais-HierarchicalMenu__itemLink:after {
    display: none; }

.ais-HierarchicalMenu__showMore {
  background: transparent;
  border: none;
  cursor: pointer; }

.ais-HierarchicalMenu__showMoreDisabled[disabled], .ais-HierarchicalMenu__showMoreDisabled:disabled {
  opacity: 0.5;
  pointer-events: none; }

.ais-Hits__root button {
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2) !important;
  border: solid 1px #d4d8e3 !important;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #697782;
  border-radius: 4px;
  outline: none; }
  .ais-Hits__root button:focus {
    background: #f2f2f2; }

.ais-Highlight .ais-Highlight__highlighted {
  background: #bccef7;
  font-style: normal; }

.ais-HitsPerPage__root {
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2) !important;
  border: solid 1px #d4d8e3 !important;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #697782;
  border-radius: 4px;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: none;
  background: #ffffff url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg'><path d='M11 1L6.02 6 1 1' stroke-width='1.5' stroke='%23BFC7D8' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>") no-repeat center right 16px/10px;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  border: solid 1px #d4d8e3;
  padding: 8px 16px;
  padding-right: 32px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #697782;
  -webkit-transition: background 0.2s ease, box-shadow 0.2s ease;
  transition: background 0.2s ease, box-shadow 0.2s ease;
  border-radius: 4px;
  text-align: left; }
  .ais-HitsPerPage__root:focus {
    background: #f2f2f2; }
  .ais-HitsPerPage__root:hover, .ais-HitsPerPage__root:active {
    box-shadow: none; }
  .ais-HitsPerPage__root:focus, .ais-HitsPerPage__root:active {
    outline: none;
    background: #f2f2f2 url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg'><path d='M11 1L6.02 6 1 1' stroke-width='1.5' stroke='%23BFC7D8' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>") no-repeat center right 16px/10px; }

.ais-InfiniteHits__root button {
  background: transparent;
  border: none;
  cursor: pointer; }

.ais-Menu__root {
  max-width: 300px; }

.ais-Menu__items {
  color: #3369e7;
  width: 100%;
  float: left; }

.ais-Menu__item {
  position: relative;
  width: 100%;
  font-size: 14px;
  min-height: 22px;
  line-height: 22px; }

.ais-Menu__item a {
  outline: 0; }

.ais-Menu__itemSelected {
  font-weight: bold; }

.ais-Menu__itemLink {
  color: currentColor;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  
}


.ais-Menu__itemCount {
  border-radius: 31px;
  background-color: rgba(39, 81, 175, 0.1);
  color: #3369e7;
  font-size: 12px;
  padding: 2px 10px; }

.ais-Menu__showMore {
  background: transparent;
  border: none;
  cursor: pointer; }

.ais-Menu__showMoreDisabled[disabled], .ais-Menu__showMoreDisabled:disabled {
  opacity: 0.5;
  pointer-events: none; }

.ais-Menu__SearchBox {
  margin-bottom: 3px; }

.ais-Menu__SearchBox .ais-SearchBox__root {
  display: inline-block;
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 32px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px; }

.ais-Menu__SearchBox .ais-SearchBox__wrapper {
  width: 100%;
  height: 100%; }

.ais-Menu__SearchBox .ais-SearchBox__input {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #d4d8e3;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 1px solid #D4D8E3;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  padding: 0;
  padding-right: 26px;
  padding-left: 32px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  appearance: none; }
  .ais-Menu__SearchBox .ais-SearchBox__input::-webkit-search-decoration, .ais-Menu__SearchBox .ais-SearchBox__input::-webkit-search-cancel-button, .ais-Menu__SearchBox .ais-SearchBox__input::-webkit-search-results-button, .ais-Menu__SearchBox .ais-SearchBox__input::-webkit-search-results-decoration {
    display: none; }
  .ais-Menu__SearchBox .ais-SearchBox__input:hover, .ais-Menu__SearchBox .ais-SearchBox__input:active, .ais-Menu__SearchBox .ais-SearchBox__input:focus {
    box-shadow: none;
    outline: 0; }
  .ais-Menu__SearchBox .ais-SearchBox__input::-webkit-input-placeholder {
    color: #9faab2; }
  .ais-Menu__SearchBox .ais-SearchBox__input::-moz-placeholder {
    color: #9faab2; }
  .ais-Menu__SearchBox .ais-SearchBox__input:-ms-input-placeholder {
    color: #9faab2; }
  .ais-Menu__SearchBox .ais-SearchBox__input::placeholder {
    color: #9faab2; }

.ais-Menu__SearchBox .ais-SearchBox__submit {
  position: absolute;
  top: 0;
  right: inherit;
  left: 0;
  margin: 0;
  border: 0;
  border-radius: 3px 0 0 3px;
  background-color: rgba(255, 255, 255, 0);
  padding: 0;
  width: 32px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .ais-Menu__SearchBox .ais-SearchBox__submit::before {
    display: inline-block;
    margin-right: -4px;
    height: 100%;
    vertical-align: middle;
    content: "" 2; }
  .ais-Menu__SearchBox .ais-SearchBox__submit:hover, .ais-Menu__SearchBox .ais-SearchBox__submit:active {
    cursor: pointer; }
  .ais-Menu__SearchBox .ais-SearchBox__submit:focus {
    outline: 0; }
  .ais-Menu__SearchBox .ais-SearchBox__submit svg {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    fill: #bfc7d8; }

.ais-Menu__SearchBox .ais-SearchBox__reset {
  display: none;
  position: absolute;
  top: 6px;
  right: 6px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  fill: #bfc7d8; }
  .ais-Menu__SearchBox .ais-SearchBox__reset:focus {
    outline: 0; }
  .ais-Menu__SearchBox .ais-SearchBox__reset svg {
    display: block;
    margin: 4px;
    width: 12px;
    height: 12px; }

.ais-Menu__SearchBox .ais-SearchBox__input:valid ~ .ais-Menu__SearchBox .ais-SearchBox__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
          animation-name: sbx-reset-in;
  -webkit-animation-duration: 250ms;
          animation-duration: 250ms; }

@-webkit-keyframes sbx-reset-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes sbx-reset-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.ais-MultiRange__items {
  font-size: 14px;
  min-height: 22px;
  line-height: 22px; }

.ais-MultiRange__item {
  font-weight: normal;
  margin-top: 6px;
  color: #55606e; }
  .ais-MultiRange__item .ais-MultiRange__itemBox {
    display: inline-block;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    background: #ffffff url("data:image/svg+xml;utf8,<svg viewBox='0 0 10 9' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.88l2.378 2.435L9.046 1.6' stroke-width='1.6' stroke='%23FFF' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/></svg>") no-repeat 50%/10px;
    box-shadow: inset 0 0 0 1px #d4d8e3;
    outline: none;
    vertical-align: middle;
    margin-right: 8px;
    -webkit-transition: all .15s ease;
    transition: all .15s ease; }
  .ais-MultiRange__item:hover input[type="radio"] {
    box-shadow: inset 0 0 0 2px #d4d8e3; }
  .ais-MultiRange__item input[type="radio"]:checked ~ .ais-MultiRange__itemBox {
    background: #3369e7 url("data:image/svg+xml;utf8,<svg viewBox='0 0 10 9' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.88l2.378 2.435L9.046 1.6' stroke-width='1.6' stroke='%23FFF' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/></svg>") no-repeat 50%/10px;
    border-color: #3369e7;
    box-shadow: none; }

.ais-MultiRange__itemSelected {
  color: #3369e7;
  font-weight: 600; }

.ais-MultiRange__itemRadio {
  display: none; }

.ais-MultiRange__itemNoRefinement {
  opacity: .7; }

.ais-Pagination__root {
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  border: solid 1px #d4d8e3;
  width: auto;
  display: inline-block;
  border-radius: 4px;
  padding: 8px 16px; }

.ais-Pagination__item {
  display: inline-block;
  padding: 3px;
  width: 28px;
  border-radius: 4px;
  text-align: center;
  font-size: 14px; }
  .ais-Pagination__item:hover {
    background: rgba(39, 81, 175, 0.1); }
    .ais-Pagination__item:hover .ais-Pagination__itemLink {
      color: #3369e7; }

.ais-Pagination__itemSelected {
  color: #fff;
  background: #3369e7; }
  .ais-Pagination__itemSelected .ais-Pagination__itemLink {
    color: currentColor;
    font-weight: normal; }

.ais-Pagination__itemDisabled {
  visibility: visible;
  color: #bbbbbb; }
  .ais-Pagination__itemDisabled[disabled], .ais-Pagination__itemDisabled:disabled {
    opacity: 0.5;
    pointer-events: none; }

.ais-Pagination__itemDisabled:hover {
  cursor: default;
  text-decoration: none; }

.ais-Pagination__itemLink {
  text-decoration: none;
  color: #697782;
  display: block;
  width: 100%;
  height: 100%; }

.ais-PoweredBy__root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.ais-PoweredBy__algoliaLink {
  padding: 0 5px; }

.ais-PoweredBy__algoliaLink svg {
  width: 45px;
  height: 16px;
  vertical-align: middle; }

.ais-RangeInput__fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

.ais-RangeInput__labelMin,
.ais-RangeInput__labelMax {
  min-width: 80px;
  display: inline-block; }

.ais-RangeInput__inputMin, .ais-RangeInput__inputMax {
  font-weight: normal;
  padding: 6px 8px;
  font-size: 14px;
  outline: none;
  color: #697782;
  margin: auto 8px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #d4d8e3; }
  .ais-RangeInput__inputMin::-webkit-input-placeholder, .ais-RangeInput__inputMax::-webkit-input-placeholder {
    font-size: 14px; }
  .ais-RangeInput__inputMin:-moz-placeholder, .ais-RangeInput__inputMax:-moz-placeholder {
    /* Firefox 18- */
    font-size: 14px; }
  .ais-RangeInput__inputMin::-moz-placeholder, .ais-RangeInput__inputMax::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 14px; }
  .ais-RangeInput__inputMin:-ms-input-placeholder, .ais-RangeInput__inputMax:-ms-input-placeholder {
    font-size: 14px; }

.ais-RangeInput__submit {
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2) !important;
  border: solid 1px #d4d8e3 !important;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #697782;
  border-radius: 4px;
  outline: none; }
  .ais-RangeInput__submit:focus {
    background: #f2f2f2; }

.ais-StarRating__root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.ais-StarRating__ratingIcon:before {
  content: '\2605';
  color: #FBAE00; }

.ais-StarRating__ratingIconEmpty:before {
  content: '\2605';
  color: #bfc7d8;
  opacity: 0.5; }

.ais-StarRating__ratingLink {
  text-decoration: none;
  color: #55606e;
  font-weight: 400;
  margin-top: 6px; }

.ais-StarRating__ratingLinkSelected {
  color: #3369e7; }

.ais-StarRating__ratingCountDisabled {
  display: none; }

.ais-StarRating__ratingIconEmptyDisabled:before {
  color: #C9C9C9;
  opacity: 1; }

.ais-StarRating__ratingIconDisabled:before {
  color: #A9A9A9; }

.ais-StarRating__ratingLabelSelected {
  font-weight: 600; }

.ais-StarRating__ratingCount {
  border-radius: 31px;
  background-color: rgba(39, 81, 175, 0.1);
  color: #3369e7;
  font-size: 12px;
  padding: 2px 10px; }

.ais-StarRating__ratingCountSelected {
  font-weight: 600; }

.ais-StarRating__ratingLinkDisabled[disabled], .ais-StarRating__ratingLinkDisabled:disabled {
  opacity: 0.5;
  pointer-events: none; }

.ais-RefinementList__item {
  color: #3369e7;
  margin-top: 6px; }
  .ais-RefinementList__item input[type="checkbox"] {
    display: none; }
  .ais-RefinementList__item .ais-RefinementList__itemBox {
    display: inline-block;
    border-radius: 3.5px;
    width: 16px;
    height: 16px;
    background: #ffffff url("data:image/svg+xml;utf8,<svg viewBox='0 0 10 9' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.88l2.378 2.435L9.046 1.6' stroke-width='1.6' stroke='%23FFF' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/></svg>") no-repeat 50%/10px;
    box-shadow: inset 0 0 0 1px #d4d8e3;
    outline: none;
    vertical-align: middle;
    margin-right: 8px;
    -webkit-transition: all .15s ease;
    transition: all .15s ease; }
  .ais-RefinementList__item input[type="checkbox"]:checked ~ .ais-RefinementList__itemBox {
    background: #3369e7 url("data:image/svg+xml;utf8,<svg viewBox='0 0 10 9' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.88l2.378 2.435L9.046 1.6' stroke-width='1.6' stroke='%23FFF' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/></svg>") no-repeat 50%/10px;
    border-color: #3369e7;
    box-shadow: none; }
  .ais-RefinementList__item:hover .ais-RefinementList__itemBox {
    box-shadow: inset 0 0 0 2px #d4d8e3; }

.ais-RefinementList__itemSelected {
  font-weight: 600; }

.ais-RefinementList__itemLabel {
  cursor: pointer; }

.ais-RefinementList__itemCount {
  border-radius: 31px;
  background-color: rgba(39, 81, 175, 0.1);
  color: #3369e7;
  font-size: 12px;
  padding: 2px 10px; }

.ais-RefinementList__showMore {
  background: transparent;
  border: none;
  cursor: pointer; }

.ais-RefinementList__showMoreDisabled[disabled], .ais-RefinementList__showMoreDisabled:disabled {
  opacity: 0.5;
  pointer-events: none; }

.ais-RefinementList__SearchBox {
  margin-bottom: 3px; }

.ais-RefinementList__SearchBox .ais-SearchBox__root {
  display: inline-block;
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 32px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px; }

.ais-RefinementList__SearchBox .ais-SearchBox__wrapper {
  width: 100%;
  height: 100%; }

.ais-RefinementList__SearchBox .ais-SearchBox__input {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #d4d8e3;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 1px solid #D4D8E3;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  padding: 0;
  padding-right: 26px;
  padding-left: 32px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  appearance: none; }
  .ais-RefinementList__SearchBox .ais-SearchBox__input::-webkit-search-decoration, .ais-RefinementList__SearchBox .ais-SearchBox__input::-webkit-search-cancel-button, .ais-RefinementList__SearchBox .ais-SearchBox__input::-webkit-search-results-button, .ais-RefinementList__SearchBox .ais-SearchBox__input::-webkit-search-results-decoration {
    display: none; }
  .ais-RefinementList__SearchBox .ais-SearchBox__input:hover, .ais-RefinementList__SearchBox .ais-SearchBox__input:active, .ais-RefinementList__SearchBox .ais-SearchBox__input:focus {
    box-shadow: none;
    outline: 0; }
  .ais-RefinementList__SearchBox .ais-SearchBox__input::-webkit-input-placeholder {
    color: #9faab2; }
  .ais-RefinementList__SearchBox .ais-SearchBox__input::-moz-placeholder {
    color: #9faab2; }
  .ais-RefinementList__SearchBox .ais-SearchBox__input:-ms-input-placeholder {
    color: #9faab2; }
  .ais-RefinementList__SearchBox .ais-SearchBox__input::placeholder {
    color: #9faab2; }

.ais-RefinementList__SearchBox .ais-SearchBox__submit {
  position: absolute;
  top: 0;
  right: inherit;
  left: 0;
  margin: 0;
  border: 0;
  border-radius: 3px 0 0 3px;
  background-color: rgba(255, 255, 255, 0);
  padding: 0;
  width: 32px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .ais-RefinementList__SearchBox .ais-SearchBox__submit::before {
    display: inline-block;
    margin-right: -4px;
    height: 100%;
    vertical-align: middle;
    content: "" 2; }
  .ais-RefinementList__SearchBox .ais-SearchBox__submit:hover, .ais-RefinementList__SearchBox .ais-SearchBox__submit:active {
    cursor: pointer; }
  .ais-RefinementList__SearchBox .ais-SearchBox__submit:focus {
    outline: 0; }
  .ais-RefinementList__SearchBox .ais-SearchBox__submit svg {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    fill: #bfc7d8; }

.ais-RefinementList__SearchBox .ais-SearchBox__reset {
  display: none;
  position: absolute;
  top: 6px;
  right: 6px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  fill: #bfc7d8; }
  .ais-RefinementList__SearchBox .ais-SearchBox__reset:focus {
    outline: 0; }
  .ais-RefinementList__SearchBox .ais-SearchBox__reset svg {
    display: block;
    margin: 4px;
    width: 12px;
    height: 12px; }

.ais-RefinementList__SearchBox .ais-SearchBox__input:valid ~ .ais-RefinementList__SearchBox .ais-SearchBox__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
          animation-name: sbx-reset-in;
  -webkit-animation-duration: 250ms;
          animation-duration: 250ms; }

@keyframes sbx-reset-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.ais-SearchBox__root {
  display: inline-block;
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 46px;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px; }

.ais-SearchBox__wrapper {
  width: 100%;
  height: 100%; }

.ais-SearchBox__input {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #d4d8e3;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  -webkit-transition: box-shadow .4s ease, background .4s ease;
  transition: box-shadow .4s ease, background .4s ease;
  border: 1px solid #D4D8E3;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  padding: 0;
  padding-right: 36px;
  padding-left: 46px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
  font-size: inherit;
  appearance: none; }
  .ais-SearchBox__input::-webkit-search-decoration, .ais-SearchBox__input::-webkit-search-cancel-button, .ais-SearchBox__input::-webkit-search-results-button, .ais-SearchBox__input::-webkit-search-results-decoration {
    display: none; }
  .ais-SearchBox__input:hover, .ais-SearchBox__input:active, .ais-SearchBox__input:focus {
    box-shadow: none;
    outline: 0; }
  .ais-SearchBox__input::-webkit-input-placeholder {
    color: #9faab2; }
  .ais-SearchBox__input::-moz-placeholder {
    color: #9faab2; }
  .ais-SearchBox__input:-ms-input-placeholder {
    color: #9faab2; }
  .ais-SearchBox__input::placeholder {
    color: #9faab2; }

.ais-SearchBox__submit {
  position: absolute;
  top: 0;
  right: inherit;
  left: 0;
  margin: 0;
  border: 0;
  border-radius: 4px 0 0 4px;
  background-color: rgba(255, 255, 255, 0);
  padding: 0;
  width: 46px;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .ais-SearchBox__submit::before {
    display: inline-block;
    margin-right: -4px;
    height: 100%;
    vertical-align: middle;
    content: "" 2; }
  .ais-SearchBox__submit:hover, .ais-SearchBox__submit:active {
    cursor: pointer; }
  .ais-SearchBox__submit:focus {
    outline: 0; }
  .ais-SearchBox__submit svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    fill: #bfc7d8; }

.ais-SearchBox__reset {
  display: none;
  position: absolute;
  top: 13px;
  right: 13px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  fill: #bfc7d8; }
  .ais-SearchBox__reset:focus {
    outline: 0; }
  .ais-SearchBox__reset svg {
    display: block;
    margin: 4px;
    width: 12px;
    height: 12px; }

.ais-SearchBox__input:valid ~ .ais-SearchBox__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
          animation-name: sbx-reset-in;
  -webkit-animation-duration: 250ms;
          animation-duration: 250ms; }

@keyframes sbx-reset-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.ais-SortBy__root {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: none;
  background: #ffffff url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg'><path d='M11 1L6.02 6 1 1' stroke-width='1.5' stroke='%23BFC7D8' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>") no-repeat center right 16px/10px;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  border: solid 1px #d4d8e3;
  padding: 8px 16px;
  padding-right: 32px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #697782;
  -webkit-transition: background 0.2s ease, box-shadow 0.2s ease;
  transition: background 0.2s ease, box-shadow 0.2s ease;
  border-radius: 4px;
  text-align: left; }
  .ais-SortBy__root:hover, .ais-SortBy__root:active {
    box-shadow: none; }
  .ais-SortBy__root:focus, .ais-SortBy__root:active {
    outline: none;
    background: #f2f2f2 url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg'><path d='M11 1L6.02 6 1 1' stroke-width='1.5' stroke='%23BFC7D8' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>") no-repeat center right 16px/10px; }

.ais-Stats__root {
  color: #697782;
  font-size: 14px;
  opacity: 0.6;
  font-stretch: normal; }

.ais-Toggle__root {
  cursor: pointer;
  display: inline-block; }

.ais-Toggle__label {
  border-radius: 31px;
  background: #ffffff url("data:image/svg+xml;utf8,<svg viewBox='0 0 10 11' xmlns='http://www.w3.org/2000/svg'><g stroke='%23bfc7d8' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round'><path d='M5 1.5v8M9 5.5H1'/></g></svg>");
  border: solid 1px #d4d8e3;
  padding: 6px 16px;
  margin-right: 8px;
  padding-right: 32px;
  text-align: center;
  line-height: 3;
  color: #697782;
  -webkit-transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease;
  transition: background-color 0.2s ease, border 0.2s ease, color 0.2s ease;
  background-repeat: no-repeat;
  background-position: center right 12px;
  background-size: 13px; }
  .ais-Toggle__label:hover {
    background-color: rgba(51, 105, 231, 0.1);
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 10 11' xmlns='http://www.w3.org/2000/svg'><g stroke='%233369e7' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round'><path d='M5 1.5v8M9 5.5H1'/></g></svg>");
    border-color: rgba(51, 105, 231, 0.1);
    color: #3369e7; }
    @supports (src: local("")) and (not (background-blend-mode: luminosity)) {
      .ais-Toggle__label:hover {
        background-color: rgba(51, 105, 231, 0.1); } }

.ais-Toggle__checkbox {
  display: none; }
  .ais-Toggle__checkbox:checked ~ .ais-Toggle__label {
    background-color: #3369e7;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 10 8' xmlns='http://www.w3.org/2000/svg'><title>2327B09A-11AB-4006-AA45-EF64831E05A2</title><path d='M1 4.28l2.378 2.435L9.046 1' stroke-width='1.6' stroke='%23FFF' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    color: #fff;
    border-color: #3369e7; }
    .ais-Toggle__checkbox:checked ~ .ais-Toggle__label:hover {
      background-color: #1a54dc;
      background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 10 8' xmlns='http://www.w3.org/2000/svg'><title>2327B09A-11AB-4006-AA45-EF64831E05A2</title><path d='M1 4.28l2.378 2.435L9.046 1' stroke-width='1.6' stroke='%23FFF' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/></svg>");
      color: #fff;
      border-color: #1a54dc; }

.ais-Panel__title {
  margin: 0 0 6px;
  padding: 0 0 6px;
  text-transform: uppercase;
  border-bottom: 2px solid #eee; }

.ais-Panel__noRefinement {
  opacity: .7; }
